import React from 'react';
import { auth } from '../firebase';
import { SignIn } from './SignIn';
import { useTheme } from '@mui/material';

const ChatMessage = (props) => {
  const { text, uid } = props.message;
  const theme = useTheme();

  const messageClass =
    auth.currentUser && uid === auth.currentUser.uid
      ? "sent"
      : uid === "no-id-yet"
      ? "sent"
      : "received";

  const messageStyle = {
    backgroundColor: messageClass === "sent" ? theme.palette.primary.light : "#fff",
    padding: "10px 20px",
    borderRadius: "20px",
    margin: "10px",
    maxWidth: "70%",
    wordWrap: "break-word",
    boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: messageClass === "sent" ? "flex-end" : "flex-start",
    padding: "0 10px",
  };

  return (
    <div style={containerStyle}>
      <div style={messageStyle}>
        {uid === "login" ? (
          <SignIn text="Please sign in to continue" />
        ) : (
          <p style={{ margin: 0 }}>{text}</p>
        )}
      </div>
    </div>
  );
};

export default ChatMessage; 