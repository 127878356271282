import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import GroupsIcon from '@mui/icons-material/Groups';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ScrollAnimation from './ScrollAnimation';
import EarlyAccessDialog from './EarlyAccessDialog';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

const steps = [
  {
    icon: <ChatIcon fontSize="large" color="primary" />,
    title: 'Message Us',
    description: "Simply send us a message describing what you need help with - we're here to assist with any task.",
    step: '01'
  },
  {
    icon: <GroupsIcon fontSize="large" color="primary" />,
    title: 'Talk to Relevant Assistant',
    description: "Connect with specialized professionals and assistants who have the expertise to help with your specific needs.",
    step: '02'
  },
  {
    icon: <TaskAltIcon fontSize="large" color="primary" />,
    title: 'Task Accomplished',
    description: "Relax while your assistant delivers high-quality results, keeping you updated every step of the way.",
    step: '03'
  }
];

function HowItWorks() {
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track('How It Works Section Viewed', {
      location: 'How It Works Section'
    });
  }, []);

  const handleTryItNow = () => {
    mixpanel.track('How It Works Get Started Click', {
      location: 'How It Works Section',
      buttonType: 'primary'
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('auth', 'signup');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const handleStepClick = (stepTitle, stepNumber) => {
    mixpanel.track('How It Works Step Click', {
      location: 'How It Works Section',
      stepTitle,
      stepNumber
    });
  };

  // Get dialog state from URL
  const searchParams = new URLSearchParams(window.location.search);
  const authMode = searchParams.get('auth');
  const isDialogOpen = authMode === 'signup' || authMode === 'login';

  const handleDialogClose = () => {
    mixpanel.track('How It Works Dialog Closed', {
      location: 'How It Works Section'
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('auth');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Box sx={{ py: 12, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <ScrollAnimation>
          <Typography 
            variant="h2" 
            textAlign="center" 
            gutterBottom
            sx={{
              fontSize: { xs: '2rem', md: '3rem' },
              fontWeight: 700,
              mb: 8
            }}
          >
            How It Works
          </Typography>
          
          {/* Desktop View */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid container spacing={4} alignItems="center">
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} md={4}>
                    <Box 
                      onClick={() => handleStepClick(step.title, step.step)}
                      sx={{ 
                        position: 'relative',
                        textAlign: 'center',
                        p: 4,
                        cursor: 'pointer',
                        '&:hover': {
                          transform: 'scale(1.02)',
                          transition: 'transform 0.2s ease-in-out'
                        },
                        '&::after': index !== steps.length - 1 ? {
                          content: '""',
                          position: 'absolute',
                          top: '30%',
                          right: '-10%',
                          width: '20%',
                          height: '2px',
                          bgcolor: 'divider',
                          zIndex: 0
                        } : {}
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '1.5rem',
                          fontWeight: 700,
                          color: 'primary.main',
                          mb: 2,
                        }}
                      >
                        {step.step}
                      </Typography>
                      <Box sx={{ 
                        bgcolor: 'background.paper',
                        borderRadius: '50%',
                        width: 80,
                        height: 80,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 auto',
                        mb: 3,
                        boxShadow: 1
                      }}>
                        {step.icon}
                      </Box>
                      <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
                        {step.title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {step.description}
                      </Typography>
                    </Box>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>

          {/* Mobile View */}
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            {steps.map((step, index) => (
              <Box
                key={index}
                onClick={() => handleStepClick(step.title, step.step)}
                sx={{
                  position: 'relative',
                  pl: 4,
                  pb: 6,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'rgba(0, 0, 0, 0.02)',
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: '15px',
                    top: 0,
                    bottom: 0,
                    width: '2px',
                    bgcolor: 'divider',
                    zIndex: 0
                  }
                }}
              >
                <Box sx={{ position: 'relative', mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: '1.25rem',
                      fontWeight: 700,
                      color: 'primary.main',
                      mb: 2
                    }}
                  >
                    {step.step}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'flex-start',
                    position: 'relative'
                  }}>
                    <Box sx={{ 
                      position: 'absolute',
                      left: '-32px',
                      bgcolor: 'background.paper',
                      borderRadius: '50%',
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: 1,
                      zIndex: 1
                    }}>
                      {React.cloneElement(step.icon, { fontSize: 'small' })}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: 600, ml: 2 }}>
                        {step.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {step.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box sx={{ textAlign: 'center', mt: 8 }}>
            <Button 
              variant="contained" 
              size="large" 
              onClick={handleTryItNow}
              sx={{
                px: 6,
                py: 1.5,
              }}
            >
              Get Started
            </Button>
          </Box>
        </ScrollAnimation>
      </Container>

      <EarlyAccessDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        isCloseable={true}
        initialTab={0}
      />
    </Box>
  );
}

export default HowItWorks;
