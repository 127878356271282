import { useState, useEffect } from 'react';

export function useAuthDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // Handle URL changes
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const authParam = params.get('auth');
    
    if (authParam) {
      setIsOpen(true);
      setActiveTab(authParam === 'login' ? 1 : 0);
    } else {
      setIsOpen(false);
    }
  }, []);

  // Handle browser back/forward
  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search);
      const authParam = params.get('auth');
      
      if (authParam) {
        setIsOpen(true);
        setActiveTab(authParam === 'login' ? 1 : 0);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  const openDialog = (type = 'signup') => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('auth', type);
    window.history.pushState({}, '', newUrl);
    setIsOpen(true);
    setActiveTab(type === 'login' ? 1 : 0);
  };

  const closeDialog = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete('auth');
    window.history.pushState({}, '', newUrl);
    setIsOpen(false);
  };

  return {
    isOpen,
    activeTab,
    openDialog,
    closeDialog,
    setActiveTab
  };
} 