import React from 'react';
import { Grid, Typography, Container, Box, Link } from '@mui/material';

export const CookiePolicy = () => {
  return (
    <div style={{ margin: '0 auto', padding: '0 20px' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        sx={{ mb: 4 }}
      >
        <Typography
          variant="h4"
          sx={{
            p: 0,
            m: 0,
            fontWeight: 700,
            py: 1,
            textAlign: 'center',
          }}
        >
          Cookie Policy
        </Typography>
      </Grid>

      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Typography variant="body1" paragraph>
            <strong>Effective Date:</strong> March 14, 2024
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>1. What Are Cookies?</Typography>
          <Typography variant="body1" paragraph>
            Cookies are small text files placed on your device when you visit a website. They are widely used to improve the user experience and enable certain features.
          </Typography>
          
          <Typography variant="h6" sx={{ mb: 1 }}>Types of Cookies:</Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li><strong>Essential Cookies:</strong> Necessary for the operation of the Website (e.g., enabling secure login).</li>
            <li><strong>Performance Cookies:</strong> Collect information about how you use the Website to improve its performance.</li>
            <li><strong>Functional Cookies:</strong> Remember your preferences and settings (e.g., language preference).</li>
            <li><strong>Targeting/Advertising Cookies:</strong> Track your online activities to deliver personalized ads.</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>2. How We Use Cookies</Typography>
          <Typography variant="body1" paragraph>
            We use cookies to:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Provide essential Website functionality</li>
            <li>Analyze Website traffic and usage</li>
            <li>Remember your preferences</li>
            <li>Deliver relevant advertisements</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>3. Third-Party Cookies</Typography>
          <Typography variant="body1" paragraph>
            Some cookies on our Website are set by third-party services. These include:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li><strong>Google Analytics:</strong> For analyzing Website traffic and usage patterns</li>
            <li><strong>Stripe/PayPal:</strong> For secure payment processing</li>
            <li><strong>Advertising Networks:</strong> For delivering personalized ads</li>
          </Typography>
          <Typography variant="body1" paragraph>
            We do not control these third-party cookies. Please refer to the respective privacy policies for more information.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>4. Managing Cookies</Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>How to Disable Cookies:</Typography>
          <Typography variant="body1" paragraph>
            You can manage or disable cookies through your browser settings. Please note that disabling cookies may affect the functionality of the Website.
          </Typography>

          <Typography variant="h6" sx={{ mb: 1 }}>Links to Manage Cookies:</Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li><Link href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener">Google Chrome: Manage Cookies</Link></li>
            <li><Link href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop" target="_blank" rel="noopener">Firefox: Manage Cookies</Link></li>
            <li><Link href="https://support.apple.com/guide/safari/manage-cookies-sfri11471" target="_blank" rel="noopener">Safari: Manage Cookies</Link></li>
            <li><Link href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noopener">Edge: Manage Cookies</Link></li>
          </Typography>

          <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>Opt-Out Tools:</Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li><Link href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener">Google Analytics Opt-Out: Download</Link></li>
            <li><Link href="https://www.youronlinechoices.com" target="_blank" rel="noopener">Advertising Cookies Opt-Out: Your Online Choices</Link></li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>5. Updates to This Policy</Typography>
          <Typography variant="body1" paragraph>
            We may update this Cookie Policy from time to time to reflect changes in technology, legislation, or our business practices. 
            Updates will be posted on this page with a clear timestamp.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>6. Contact Us</Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Cookie Policy, please contact us:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Email: <a href="mailto:support@uniget.com">support@uniget.com</a></li>
            <li>Phone: +972-50-1234567</li>
          </Typography>
        </Box>

        <Box sx={{ mt: 6, mb: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            &copy; {new Date().getFullYear()} Uniget. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}; 