import React from 'react';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScrollAnimation from './ScrollAnimation';

const faqs = [
  {
    question: "How quickly can I get started?",
    answer: "You can start delegating tasks immediately after signing up. Our onboarding process takes less than 5 minutes, and you'll be matched with an assistant right away."
  },
  {
    question: "What types of tasks can I delegate?",
    answer: "From research and scheduling to travel planning and personal shopping - if it's legal and can be done remotely, we can help. Our assistants handle both personal and professional tasks."
  },
  {
    question: "How are your assistants vetted?",
    answer: "Every assistant undergoes a rigorous screening process including background checks, skill assessments, and multiple interviews. Only the top 1% of applicants join our team."
  },
  {
    question: "What if I'm not satisfied?",
    answer: "Your satisfaction is our priority. If you're not happy with any task, we'll revise it immediately or provide a full refund - no questions asked."
  },
  {
    question: "Is my information secure?",
    answer: "Absolutely. We use bank-level encryption and strict data protection protocols. All our assistants sign comprehensive NDAs, and we never share your information with third parties."
  }
];

function FAQ() {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <ScrollAnimation>
          <Typography variant="h2" textAlign="center" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Box sx={{ mt: 4 }}>
            {faqs.map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </ScrollAnimation>
      </Container>
    </Box>
  );
}

export default FAQ;
