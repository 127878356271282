import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import ScrollAnimation from "./ScrollAnimation";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DiscountIcon from '@mui/icons-material/Discount';
import EarlyAccessDialog from './EarlyAccessDialog';
import mixpanel from 'mixpanel-browser';

const plans = [
  {
    title: "Standard",
    price: "Pay\u00A0as\u00A0you\u00A0go",
    discountPerTask: "0%",
    description: "Expert assistance for individuals with basic needs.",
    features: [
      "Access to expert assistance and professionals on-demand",
      "Pure pay-as-you-go pricing with no upfront costs",
      "Fast and Reliable Results",
      "0% discount on all tasks",
    ],
    buttonText: "Get Started",
  },
  {
    title: "Pro",
    price: "$79",
    discountPerTask: "3%",
    description: "Expert assistance for individuals with regular needs.",
    features: [
      "Access to expert assistance and professionals on-demand",
      "Pay-as-you-go pricing with 79 dollar base credit and then can pay for tasks as you go",
      "Priority and Reliable Results",
      "3% discount on all tasks",
    ],
    buttonText: "Choose Pro",
    highlighted: true,
    mostPopular: true,
  },
  {
    title: "Premium",
    price: "$199",
    discountPerTask: "6%",
    description: "Expert assistance for individuals and small teams.",
    features: [
      "Access to expert assistance and professionals on-demand",
      "Pay-as-you-go pricing with 199 dollar base credit and then can pay for tasks as you go",
      "VIP and Reliable Results",
      "6% discount on all tasks"
    ],
    buttonText: "Choose Premium",
  },
  {
    title: "Elite",
    price: "Custom",
    discountPerTask: "Custom",
    description: "Expert assistance for individuals and enterprises.",
    features: [
      "Access to expert assistance and professionals on-demand",
      "Custom base credit and pay-as-you-go pricing tailored to your needs",
      "VIP and Reliable Results",  
      "Custom volume discounts",
    ],
    buttonText: "Contact Us",
  },
];

function Pricing() {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track('Pricing Section Viewed', {
      location: 'Pricing Section'
    });
  }, []);

  const handlePlanClick = (buttonText, planTitle, planPrice) => {
    mixpanel.track('Pricing Plan Selected', {
      location: 'Pricing Section',
      planTitle,
      planPrice,
      buttonText
    });

    if (buttonText === "Contact Us") {
      navigate("/contact");
    } else {
      setOpenDialog(true);
    }
  };

  const handleFeatureClick = (featureType) => {
    mixpanel.track('Pricing Feature Clicked', {
      location: 'Pricing Section',
      featureType
    });
  };

  const handleDialogClose = () => {
    mixpanel.track('Pricing Dialog Closed', {
      location: 'Pricing Section'
    });
    setOpenDialog(false);
  };

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <ScrollAnimation>
          <Typography variant="h2" textAlign="center" gutterBottom>
            Pricing
          </Typography>

          <Box sx={{ maxWidth: "1200px", mx: "auto", mb: 4 }}>
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: 4,
              px: 4,
              mt: 6
            }}>
              <Box 
                onClick={() => handleFeatureClick('Time Based Billing')}
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  gap: 2,
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
              >
                <Box 
                  sx={{ 
                    bgcolor: 'rgba(47, 107, 90, 0.1)',
                    p: 1.5,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1
                  }}
                >
                  <AccessTimeIcon 
                    sx={{ 
                      fontSize: '2rem',
                      color: '#2F6B5A'
                    }}
                  />
                </Box>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: '1.1rem', 
                    lineHeight: 1.5,
                    color: 'text.primary',
                    fontWeight: 500
                  }}
                >
                  Each task is billed based on time + a small fee based on the plan
                </Typography>
              </Box>

              <Box 
                onClick={() => handleFeatureClick('Monthly Credit')}
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  gap: 2,
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
              >
                <Box 
                  sx={{ 
                    bgcolor: 'rgba(47, 107, 90, 0.1)',
                    p: 1.5,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1
                  }}
                >
                  <AccountBalanceWalletIcon 
                    sx={{ 
                      fontSize: '2rem',
                      color: '#2F6B5A'
                    }}
                  />
                </Box>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: '1.1rem', 
                    lineHeight: 1.5,
                    color: 'text.primary',
                    fontWeight: 500
                  }}
                >
                  Your plan payment becomes monthly credit
                </Typography>
              </Box>

              <Box 
                onClick={() => handleFeatureClick('Discounted Rate')}
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center',
                  gap: 2,
                  textAlign: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
              >
                <Box 
                  sx={{ 
                    bgcolor: 'rgba(47, 107, 90, 0.1)',
                    p: 1.5,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1
                  }}
                >
                  <DiscountIcon 
                    sx={{ 
                      fontSize: '2rem',
                      color: '#2F6B5A'
                    }}
                  />
                </Box>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: '1.1rem', 
                    lineHeight: 1.5,
                    color: 'text.primary',
                    fontWeight: 500
                  }}
                >
                  After using your credit, continue at your plan's discounted rate
                </Typography>
              </Box>
            </Box>
          </Box>

          <Grid container spacing={4} justifyContent="center" sx={{ pt: 4 }}>
            {plans.map((plan, index) => (
              <Grid
                item
                xs={12}
                md={3}
                key={index}
                sx={{ 
                  mt: plan.mostPopular ? 0 : 4,
                  minWidth: { xs: '100%', md: '280px' }
                }}
              >
                <Card
                  onClick={() => handlePlanClick(plan.buttonText, plan.title, plan.price)}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    minWidth: { xs: '100%', md: '280px' },
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      transition: 'transform 0.2s ease-in-out',
                      boxShadow: 3
                    },
                    ...(plan.highlighted && {
                      border: "1px solid #2F6B5A",
                      borderRadius: "8px",
                    }),
                  }}
                >
                  {plan.mostPopular && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bgcolor: "#2F6B5A",
                        color: "white",
                        py: 1,
                        textAlign: "center",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      Most Popular
                    </Box>
                  )}
                  <CardContent
                    sx={{ flexGrow: 1, p: 2, pt: plan.mostPopular ? 6 : 2 }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          minHeight: "48px",
                          display: "flex",
                          alignItems: "center",
                          mb: 0.5,
                        }}
                      >
                        <Typography variant="h5" component="div">
                          {plan.title}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          minHeight: "80px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          mb: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            mb: 1,
                            minWidth: 0,
                            position: "relative",
                            width: "100%"
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="span"
                            sx={{
                              fontWeight: 600,
                              color: "text.primary",
                              mr: 1,
                              fontSize: { xs: '1.5rem', md: '2rem' },
                              lineHeight: 1.2,
                              whiteSpace: 'nowrap',
                              minWidth: 0,
                              width: "auto"
                            }}
                          >
                            {typeof plan.price === "number"
                              ? `$${plan.price}`
                              : plan.price}
                          </Typography>
                          {plan.price !== "Custom" && !plan.price.includes("Pay") && (
                            <Typography
                              variant="subtitle1"
                              component="span"
                              sx={{
                                color: "text.secondary",
                                fontWeight: 400,
                              }}
                            >
                              /month
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            bgcolor: "rgba(47, 107, 90, 0.1)",
                            px: 1.5,
                            py: 0.75,
                            borderRadius: 1,
                            width: "fit-content",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#2F6B5A",
                              fontWeight: 500,
                            }}
                          >
                            {plan.discountPerTask === "Custom"
                              ? "Custom Discount"
                              : `${plan.discountPerTask} discount per task`}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          minHeight: "60px",
                          display: "flex",
                          alignItems: "center",
                          mb: 0.5,
                        }}
                      >
                        <Typography variant="body2" color="text.secondary">
                          {plan.description}
                        </Typography>
                      </Box>
                      <Box sx={{ 
                        minHeight: "192px",
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        flexGrow: 1
                      }}>
                        {plan.features.map((feature, idx) => (
                          <Typography
                            key={idx}
                            variant="body2"
                            sx={{
                              minHeight: 48,
                              py: 0.5,
                              display: "flex",
                              alignItems: "flex-start",
                              flexShrink: 0,
                              flexGrow: 1
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                bgcolor: "#2F6B5A",
                                mr: 1.5,
                                mt: 0.5,
                                flexShrink: 0,
                                display: "inline-block",
                              }}
                            />
                            {feature}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Button
                      fullWidth
                      variant={plan.highlighted ? "contained" : "outlined"}
                      size="large"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlanClick(plan.buttonText, plan.title, plan.price);
                      }}
                      sx={{
                        ...(plan.highlighted && {
                          bgcolor: "#2F6B5A",
                          "&:hover": {
                            bgcolor: "#235445",
                          },
                        }),
                      }}
                    >
                      {plan.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ScrollAnimation>
      </Container>

      <EarlyAccessDialog
        open={openDialog}
        onClose={handleDialogClose}
        isCloseable={true}
        initialTab={0}
      />
    </Box>
  );
}

export default Pricing;
