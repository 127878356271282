import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Tabs,
  Tab,
  Divider,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { auth, firestore } from '../firebase';
import { SignIn } from './SignIn';
import { MoreVert, Close as CloseIcon, FileCopy, Add } from '@mui/icons-material';
import logo from "../images/logo.png";
import firebase from "firebase/compat/app";
import EarlyAccessDialog from './EarlyAccessDialog';
import { scroller } from 'react-scroll';
import mixpanel from 'mixpanel-browser';

const API_URL = "https://stingray-app-iip8m.ondigitalocean.app";
const API_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpIdW5hbkNoYWluQUlMaWtlQUJvc3MiLCJpYXQiOjE1MTYyMzkwMjJ9.won_gEy1XlbvGHa1mJlDUA6x7WxePIOAxrHKcAquoEA";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};

export const TopBar = ({ tab, isAdmin, openWaitingListProp, onShowDialog }) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [openWaitingList, setOpenWaitingList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const windowWidth = useWindowWidth();
  const [value, setValue] = useState(
    tab === "chats" ? 1 : tab === "requests" ? 2 : tab === "profile" ? 3 : 0
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(
    localStorage.getItem("mainAddress") ? true : false
  );
  const [mainAddress, setMainAddress] = useState(
    localStorage.getItem("mainAddress")
      ? JSON.parse(localStorage.getItem("mainAddress"))
      : null
  );
  const [openEarlyAccess, setOpenEarlyAccess] = useState(false);
  const [initialTab, setInitialTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const messagesRef = firestore.collection("messages");
  const [requests, setRequests] = useState([]);
  const [adminRequests, setAdminRequests] = useState([]);
  let query = messagesRef.where("uid", "==", "asdfasdf").orderBy("createdAt");
  let assistQuery = messagesRef.orderBy("createdAt").limit(1);

  if (isAdmin) {
    assistQuery = messagesRef.orderBy("createdAt");
  }

  const [adminMessages] = useCollectionData(assistQuery, { idField: "id" });

  if (user) {
    query = messagesRef.where("uid", "==", user.uid).orderBy("createdAt");
  }

  const [myMessages] = useCollectionData(query, { idField: "id" });

  useEffect(() => {
    if (myMessages !== undefined && myMessages.length > 0 && requests.length < 1) {
      let ids = [];
      myMessages.forEach((message) => {
        if (message.chatId && !ids.includes(message.chatId)) {
          let filteredArray = myMessages.filter(function (obj) {
            return obj.chatId === message.chatId;
          });
          filteredArray.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

          ids.unshift({
            id: message.chatId,
            firstMessage: filteredArray[0].text,
          });
        }
      });
      setRequests(ids);
    }
  }, [myMessages]);

  useEffect(() => {
    if (isAdmin && adminMessages !== undefined && adminMessages.length > 1) {
      let ids = [];
      adminMessages.forEach((message) => {
        if (message.chatId && !ids.includes(message.chatId)) {
          let filteredArray = adminMessages.filter(function (obj) {
            return obj.chatId === message.chatId;
          });
          filteredArray.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
          if (
            filteredArray &&
            filteredArray.length > 0 &&
            filteredArray[0].uid !== "login"
          ) {
            ids.unshift({
              id: message.chatId,
              firstMessage: filteredArray[0].text,
            });
          }
        }
      });
      setAdminRequests(ids);
    }
  }, [adminMessages, isAdmin]);

  useEffect(() => {
    setValue(
      tab === "other"
        ? -1
        : tab === "new"
        ? 0
        : tab === "chats"
        ? 1
        : tab === "requests"
        ? 2
        : tab === "profile"
        ? 3
        : -1
    );
  }, [tab]);

  useEffect(() => {
    if (openWaitingListProp) {
      setOpenWaitingList(true);
    }
  }, [openWaitingListProp]);

  useEffect(() => {
    if (mainAddress) {
      localStorage.setItem("mainAddress", JSON.stringify(mainAddress));
      setIsAddressSelected(true);
    }
  }, [mainAddress]);

  useEffect(() => {
    if (openAbout) {
      localStorage.setItem("isAboutHidden", "true");
    }
  }, [openAbout]);

  // Track initial render and auth state
  useEffect(() => {
    mixpanel.track('TopBar Viewed', {
      location: 'TopBar',
      isAuthenticated: !!user,
      isAdmin,
      currentTab: tab
    });
  }, [user, isAdmin, tab]);

  // Track auth state changes
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        mixpanel.track('User Authenticated', {
          location: 'TopBar',
          userId: user.uid,
          isAdmin
        });
      } else {
        mixpanel.track('User Logged Out', {
          location: 'TopBar'
        });
      }
      
      if (!(user || localStorage.getItem("isAboutHidden") === "true")) {
        setOpenAbout(true);
      }
    });

    return () => unsubscribe();
  }, [isAdmin]);

  const handleClickMenu = (event) => {
    mixpanel.track('Menu Opened', {
      location: 'TopBar'
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, option) => {
    setAnchorEl(null);

    if (option) {
      mixpanel.track('Menu Option Selected', {
        location: 'TopBar',
        option
      });
    }

    if (option === "Contact / Feedback") {
      navigate("/contact");
    } else if (option === "Build a Bot") {
      navigate("/chatbot");
    } else if (option === "Terms of Service") {
      navigate("/terms");
    } else if (option === "Privacy Policy") {
      navigate("/privacy");
    } else if (option === "Cookie Policy") {
      navigate("/cookies");
    } else if (option === "Log Out") {
      auth.signOut();
    } else if (option === "Login") {
      if (onShowDialog) {
        onShowDialog(1);
      }
    } else if (option === "About") {
      navigate("/about");
    } else if (option === "Pricing") {
      scrollToSection('pricing');
    }
  };

  const handleChange = (event, newValue) => {
    mixpanel.track('Tab Changed', {
      location: 'TopBar',
      previousTab: value,
      newTab: newValue
    });
    setValue(newValue);
  };

  const handleCopyUrl = () => {
    mixpanel.track('API URL Copied', {
      location: 'TopBar'
    });
    navigator.clipboard.writeText(API_URL);
  };

  const handleCopyToken = () => {
    mixpanel.track('API Token Copied', {
      location: 'TopBar'
    });
    navigator.clipboard.writeText(API_KEY);
  };

  const scrollToSection = (sectionId) => {
    mixpanel.track('Section Scroll', {
      location: 'TopBar',
      sectionId,
      fromPath: location.pathname
    });

    if (location.pathname === '/about') {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: true,
        offset: -80,
        spy: true
      });
      window.history.pushState(null, '', `/about#${sectionId}`);
    } else {
      navigate(`/about#${sectionId}`);
    }
  };

  const scrollToTop = () => {
    mixpanel.track('Scroll To Top', {
      location: 'TopBar',
      fromPath: location.pathname
    });

    if (location.pathname === '/about') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      window.history.pushState(null, '', '/about');
    } else {
      navigate('/about');
    }
  };

  // Track dialog state changes
  useEffect(() => {
    if (openWaitingList) {
      mixpanel.track('Waiting List Dialog Opened', {
        location: 'TopBar'
      });
    }
  }, [openWaitingList]);

  useEffect(() => {
    if (openAbout) {
      mixpanel.track('About Dialog Opened', {
        location: 'TopBar'
      });
    }
  }, [openAbout]);

  useEffect(() => {
    if (openEarlyAccess) {
      mixpanel.track('Early Access Dialog Opened', {
        location: 'TopBar',
        initialTab
      });
    }
  }, [openEarlyAccess, initialTab]);

  // Track screen size changes
  useEffect(() => {
    mixpanel.track('Screen Size Changed', {
      location: 'TopBar',
      isMobile,
      isTablet,
      isLargeScreen,
      windowWidth
    });
  }, [isMobile, isTablet, isLargeScreen, windowWidth]);

  const menuItems = [
    ...(isMobile ? [
      "About",
      "Pricing",
      "Contact / Feedback",
      ...(user ? [] : ["Login"])
    ] : []),
    "Terms of Service",
    "Privacy Policy",
    "Cookie Policy",
    ...(user ? ["Log Out"] : []),
  ];

  const handleLoginClick = () => {
    if (onShowDialog) {
      onShowDialog(1);
    }
  };

  const handleSignupClick = () => {
    if (onShowDialog) {
      onShowDialog(0);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(255, 254, 249)",
          marginBottom: "10px",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 999999,
          pointerEvents: "auto"
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: 1540, margin: "auto", pointerEvents: "auto" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ pointerEvents: "auto" }}
          >
            <div style={{ display: 'inline-block', position: 'relative' }}>
            <Tooltip
            
        style={{zIndex: 999999999999}}
title="Uniget - Home">
              <Link
                to="/"
                style={{
                  marginLeft: '16px',
                  padding: '10px',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: 'inherit',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  position: "relative",

                }}
              >
<Avatar sx={{ bgcolor: '#fffde6', color: 'black', fontWeight: 900, fontSize: 24 }}>U</Avatar>
              </Link>
              </Tooltip>
            </div>
            
            {user && !isMobile && (
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{
                  overflowY: "auto",
                  bgcolor: "rgb(255, 254, 243)",
                }}
              >
                <Tab
                  sx={{ fontSize: 16, py: "20px" }}
                  onClick={() => navigate("/")}
                  label="🗣️ New"
                />
                <Tab
                  sx={{ fontSize: 16, py: "20px" }}
                  onClick={() => navigate("/chats")}
                  label="💬 Chats"
                />
                <Tab
                  sx={{ fontSize: 16, py: "20px" }}
                  onClick={() => navigate("/requests")}
                  label="🔄 Requests"
                />
                <Tab
                  sx={{ fontSize: 16, py: "20px" }}
                  onClick={() => navigate("/profile")}
                  label="😁 Profile"
                />
              </Tabs>
            )}
            
            <div>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                wrap="nowrap"
                spacing={1}
              >
                {!user && (
                  <>
                    {!isMobile && (
                      <>
                        <Grid item>
                          <Button
                            variant="text"
                            href='/about'
                            sx={{
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.04)',
                              },
                              ...(isTablet && {
                                minWidth: 'auto',
                                px: 1.5,
                                fontSize: '0.875rem',
                              }),
                            }}
                          >
                            About
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="text"
                            onClick={() => scrollToSection('pricing')}
                            sx={{
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.04)',
                              },
                              ...(isTablet && {
                                minWidth: 'auto',
                                px: 1.5,
                                fontSize: '0.875rem',
                              }),
                            }}
                          >
                            Pricing
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="text"
                            href='/contact'
                            sx={{
                              color: 'black',
                              '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.04)',
                              },
                              ...(isTablet && {
                                minWidth: 'auto',
                                px: 1.5,
                                fontSize: '0.875rem',
                              }),
                            }}
                          >
                            {isTablet ? "Contact" : "Contact Sales"}
                          </Button>
                        </Grid>
                      </>
                    )}
                    {!isMobile && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={handleLoginClick}
                          sx={{
                            color: 'black',
                            borderColor: 'black',
                            '&:hover': {
                              borderColor: 'black',
                              backgroundColor: 'rgba(0,0,0,0.04)',
                            },
                            ...(isTablet && {
                              minWidth: 'auto',
                              px: 1.5,
                              fontSize: '0.875rem',
                            }),
                          }}
                        >
                          Login
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={handleSignupClick}
                        sx={{
                          bgcolor: 'black',
                          color: 'white',
                          '&:hover': {
                            bgcolor: '#333',
                          },
                          ...(isMobile && {
                            minWidth: 0,
                            px: 2,
                            fontSize: '0.875rem',
                          }),
                        }}
                      >
                        {isMobile ? "Start" : "Get Started"}
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <Tooltip title="menu">
                    <IconButton onClick={handleClickMenu}>
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                      zIndex: 9999999,
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: "20ch",
                      },
                    }}
                  >
                    {menuItems.map((option) => (
                      <MenuItem
                        key={option}
                        onClick={(e) => handleClose(e, option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: 60 }}></div>

      <Dialog
        open={openWaitingList}
        onClose={() => setOpenWaitingList(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Join Waiting List</DialogTitle>
        <DialogContent>
          <Typography>
            We're currently in beta and accepting a limited number of users.
            Please join our waiting list to get early access.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <SignIn text="Join Waiting List" />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        sx={{ zIndex: 999999999999 }}
      >
        <DialogTitle>Install Uniget ChatGPT Plugin</DialogTitle>
        <IconButton
          onClick={() => setIsDialogOpen(false)}
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box my={2}>
              <Typography variant="h6" sx={{ fontSize: "18px" }}>
                Step 1: Open Plugin Store
              </Typography>
              <Typography variant="body2">
                Start by opening the Plugin Store. Please note that to get access to ChatGPT plugins,
                you must have a subscription to the premium version, "ChatGPT Plus".
              </Typography>
            </Box>

            <Divider />

            <Box my={2}>
              <Typography variant="h6" sx={{ fontSize: "18px" }}>
                Step 2: Click on "Install an Unverified Plugin"
              </Typography>
              <Typography variant="body2">
                Next, look for the option that says "Install an Unverified Plugin".
                <br />
                <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                  The plugin is not verified yet because we just finished building it and it is still
                  in the process of being reviewed by the ChatGPT team.
                </Typography>
              </Typography>
            </Box>

            <Divider />

            <Box my={2}>
              <Typography variant="h6" sx={{ fontSize: "18px" }}>
                Step 3: Enter URL
              </Typography>
              <Typography variant="body2">
                In the provided field, enter the URL of your plugin. This should be the direct link
                to your plugin's source code or download page.
              </Typography>
              <Typography variant="body1">
                URL: <br /> {API_URL}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileCopy />}
                onClick={handleCopyUrl}
                sx={{ mb: 2 }}
              >
                Copy URL to Clipboard
              </Button>
            </Box>

            <Divider />

            <Box my={2}>
              <Typography variant="h6" sx={{ fontSize: "18px" }}>
                Step 4: Enter Token
              </Typography>
              <Typography variant="body2">
                After entering the URL, you will be asked to enter a token and then click on
                "Install Plugin".
              </Typography>
              <Typography variant="body1" sx={{ whiteSpace: "initial", width: "100%" }}>
                <span style={{ width: "100%", wordWrap: "break-word", display: "inline-block" }}>
                  Token: {API_KEY}
                </span>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FileCopy />}
                onClick={handleCopyToken}
                sx={{ mb: 2 }}
              >
                Copy Token to Clipboard
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <EarlyAccessDialog
        open={openEarlyAccess}
        onClose={() => {
          setOpenEarlyAccess(false);
          setInitialTab(0);
        }}
        initialTab={initialTab}
      />
    </>
  );
}; 