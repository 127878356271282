import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, Grid, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import ChatMessage from "./ChatMessage";
import mixpanel from "mixpanel-browser";
import MessageInput from './MessageInput';

const ChatRoom = () => {
  const theme = useTheme();
  const location = useLocation();
  const [formValue, setFormValue] = useState("");
  const [initialMessage, setInitialMessage] = useState(null);
  const mainRef = useRef(null);
  const lastTypingTime = useRef(null);
  const typingTimeout = useRef(null);
  const messageListener = useRef(null);

  // Track room initialization with proper cleanup
  useEffect(() => {
    let isSubscribed = true;

    const initializeRoom = async () => {
      try {
        if (!isSubscribed) return;

        mixpanel.track('Chat Room Initialized', {
          location: 'ChatRoom',
          hasInitialMessage: !!initialMessage,
          pathname: location.pathname
        });

        // Track scroll position
        const handleScroll = () => {
          if (!mainRef.current || !isSubscribed) return;
          
          const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
          const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
          
          if (scrollPercentage % 25 < 1) {
            mixpanel.track('Chat Room Scrolled', {
              location: 'ChatRoom',
              scrollPercentage: Math.round(scrollPercentage)
            });
          }
        };

        const mainElement = mainRef.current;
        if (mainElement) {
          mainElement.addEventListener('scroll', handleScroll);
        }

        return () => {
          if (mainElement) {
            mainElement.removeEventListener('scroll', handleScroll);
          }
        };
      } catch (error) {
        console.error('Error initializing chat room:', error);
      }
    };

    initializeRoom();

    return () => {
      isSubscribed = false;
      if (messageListener.current) {
        messageListener.current();
      }
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current);
      }
    };
  }, [location.pathname, initialMessage]);

  // Get message from URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const serviceParam = searchParams.get('service');
    const messageParam = searchParams.get('message');
    
    if (serviceParam || messageParam) {
      if (serviceParam) {
        setInitialMessage({
          text: `I'm interested in ${serviceParam}`,
          uid: 'user',
          createdAt: new Date(),
        });
      } else if (messageParam) {
        setInitialMessage({
          text: messageParam,
          uid: 'user',
          createdAt: new Date(),
        });
      }
    }
  }, [location.search]);

  // Handle typing behavior
  const handleFormChange = (e) => {
    const newValue = e.target.value;
    setFormValue(newValue);
  };

  const handleEmptyStateClick = () => {
    mixpanel.track('Empty State Clicked', {
      location: 'ChatRoom'
    });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (formValue.trim()) {
      mixpanel.track('Message Sent', {
        location: 'ChatRoom',
        messageLength: formValue.length,
        containsUrls: /https?:\/\/[^\s]+/.test(formValue),
        isReply: !!initialMessage
      });

      // Handle new message sending here if needed
      setFormValue("");
    }
  };

  return (
    <div style={{ height: "calc(100vh - 110px)", display: "flex", flexDirection: "column", marginTop: "0", width: "100%" }}>
      <main
        ref={mainRef}
        style={{
          flex: 1,
          background: theme.palette.background.chat,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          marginTop: 0,
          width: "100%",
          overflowY: "auto",
          paddingBottom: "100px",
        }}
      >
        <div style={{ 
          padding: "10px 0", 
          flexGrow: 1,
          maxWidth: "768px",
          margin: "0 auto",
          width: "100%"
        }}>
          {initialMessage ? (
            <ChatMessage message={initialMessage} />
          ) : (
            <div
              onClick={handleEmptyStateClick}
              style={{
                textAlign: "center",
                padding: "20px",
                color: "gray",
                cursor: "pointer"
              }}
            >
              No messages yet. Start a conversation!
            </div>
          )}
        </div>
      </main>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        style={{ 
          width: "100%",
          marginBottom: 0,
          background: "rgb(255, 254, 249)",
          zIndex: 1000,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "15px 8px",
            paddingRight: 30,
            maxWidth: 668,
          }}
        >
          <form onSubmit={sendMessage} style={{ width: "100%", paddingRight: 20, paddingLeft: 5 }}>
            <MessageInput
              value={formValue}
              onChange={handleFormChange}
              onSubmit={sendMessage}
              placeholder="Enter your message..."
              disabled={!formValue.trim()}
              onFocus={() => {
                mixpanel.track('Message Input Focused', {
                  location: 'ChatRoom'
                });
              }}
              onBlur={() => {
                mixpanel.track('Message Input Blurred', {
                  location: 'ChatRoom',
                  hadContent: formValue.length > 0
                });
              }}
            />
          </form>
        </div>
      </Grid>
    </div>
  );
};

export default ChatRoom;
