import React from 'react';
import { Grid, IconButton, Typography, Container, Box } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { goBackOrHome } from '../utils/navigation';

export const Privacy = () => {
  return (
    <div style={{ margin: '0 auto', padding: '0 20px' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        sx={{ mb: 4 }}
      >
        <Typography
          variant="h4"
          sx={{
            p: 0,
            m: 0,
            fontWeight: 700,
            py: 1,
            textAlign: 'center',
          }}
        >
          Privacy Policy
        </Typography>
      </Grid>

      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Typography variant="body1" paragraph>
            <strong>Effective Date:</strong> March 14, 2024
          </Typography>
          <Typography variant="body1" paragraph>
            Uniget ("Company," "we," "us," or "our") respects your privacy and is committed to protecting your personal data. 
            This privacy policy outlines how we collect, use, and protect your information when you use our website, uniget.com (the "Website").
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>1. Company Details</Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li><strong>Company Name:</strong> Uniget</li>
            <li><strong>Location:</strong> Tel Aviv, Israel</li>
            <li><strong>Contact Information:</strong> <a href="mailto:support@uniget.com">support@uniget.com</a>, +972-50-1234567</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>2. Data Collection</Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>Types of Data Collected:</Typography>
          <Typography component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>Personal Information: Name, email address, phone number</li>
            <li>Payment Details: Credit card information and transaction details</li>
            <li>Technical Information: IP address, browser details</li>
            <li>Usage Data: Pages visited, time spent on the Website</li>
          </Typography>
          
          <Typography variant="h6" sx={{ mb: 1 }}>How Data is Collected:</Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Forms: Through signup, contact, and checkout forms on the Website</li>
            <li>Cookies: Using cookies and similar tracking technologies</li>
            <li>Third-Party Integrations: From payment processors, analytics tools, and marketing platforms</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>3. Data Usage</Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>Purposes:</Typography>
          <Typography component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>Account creation and management</li>
            <li>Processing payments and fulfilling orders</li>
            <li>Improving Website performance and user experience</li>
            <li>Sending marketing and promotional communications (with user consent)</li>
          </Typography>

          <Typography variant="h6" sx={{ mb: 1 }}>Third-Party Sharing:</Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Payment Processors: Stripe, PayPal</li>
            <li>Analytics Services: Google Analytics</li>
            <li>Marketing Platforms: Mailchimp</li>
          </Typography>
          <Typography variant="body1" paragraph>
            We do not sell your personal data to third parties.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>4. User Rights</Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>Your Rights:</Typography>
          <Typography component="ul" sx={{ pl: 2, mb: 2 }}>
            <li>Access, update, or delete your personal data</li>
            <li>Opt out of marketing communications</li>
            <li>Request a copy of the data we have collected about you</li>
          </Typography>

          <Typography variant="h6" sx={{ mb: 1 }}>How to Exercise Your Rights:</Typography>
          <Typography variant="body1" paragraph>
            Email your request to <a href="mailto:support@uniget.com">support@uniget.com</a>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>5. Third-Party Services</Typography>
          <Typography variant="body1" paragraph>
            We use third-party services to provide and improve our Website:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Google Analytics: For performance monitoring</li>
            <li>Stripe/PayPal: For payment processing</li>
            <li>Mailchimp: For email communications</li>
            <li>Cloud Providers: AWS, Google Cloud for storage and hosting</li>
          </Typography>
          <Typography variant="body1" paragraph>
            These services have their own privacy policies, which we encourage you to review.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>6. Data Security</Typography>
          <Typography variant="body1" paragraph>
            We prioritize the security of your personal data. Measures include:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Encryption of sensitive data (e.g., HTTPS and encrypted payment details)</li>
            <li>Regular security audits</li>
            <li>Limited access to personal data by authorized personnel only</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>7. Minors</Typography>
          <Typography variant="body1" paragraph>
            The Website is not intended for children under the age of 13. We do not knowingly collect personal data from minors. 
            If you believe we have collected data from a minor, please contact us immediately.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>8. International Users</Typography>
          <Typography variant="body1" paragraph>
            For users outside of Israel:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li><strong>GDPR Compliance:</strong> For EU users, you have additional rights, including the right to data portability and the right to restrict processing.</li>
            <li><strong>CCPA Compliance:</strong> For California residents, you have the right to opt out of data "sales" and to request information about your data.</li>
          </Typography>
          <Typography variant="body1" paragraph>
            All data is stored and processed in Israel with appropriate protections in place.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>9. Policy Updates</Typography>
          <Typography variant="body1" paragraph>
            We may update this privacy policy from time to time. Updates will be posted on this page with a clear timestamp. 
            For significant changes, we will notify users via email.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>10. Contact for Privacy Concerns</Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this privacy policy, please contact us:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Email: <a href="mailto:support@uniget.com">support@uniget.com</a></li>
            <li>Phone: +972-50-1234567</li>
          </Typography>
        </Box>

        <Box sx={{ mt: 6, mb: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            &copy; {new Date().getFullYear()} Uniget. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}; 