import React from 'react';
import { Grid, Typography, TextField, Button, MenuItem, Snackbar, Alert } from '@mui/material';
import { useForm, ValidationError } from '@formspree/react';
import { firestore } from '../firebase';

export const Contact = () => {
  const [state, handleSubmit] = useForm("mzzdgeow");
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  React.useEffect(() => {
    if (state.succeeded) {
      setSnackbar({
        open: true,
        message: 'Thank you for your message! We will get in touch with you soon.',
        severity: 'success'
      });
    }
  }, [state.succeeded]);

  const contactMethods = [
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'either', label: 'Either' },
  ];

  return (
    <div style={{ margin: '0 auto', padding: '0 20px', maxWidth: '600px' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mb: 6, mt: 4, textAlign: 'center', width: '100%' }}
      >
        <Grid item sx={{ width: '100%' }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 600,
              color: 'rgb(49, 130, 122)',
              mb: 2
            }}
          >
            Get in Touch with Us
          </Typography>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Typography
            variant="h6"
            sx={{
              color: 'rgb(102, 102, 102)',
              fontSize: { xs: '1.1rem', md: '1.25rem' }
            }}
          >
            We're here to help! Let us know your requirements, and we'll get back to you promptly.
          </Typography>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit} style={{ position: 'relative', top: 0, margin: '0 auto' }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
        >
          <Grid item sx={{ width: '100%' }}>
            <TextField
              fullWidth
              id="fullName"
              name="fullName"
              label="Full Name"
              required
            />
            <ValidationError prefix="Full Name" field="fullName" errors={state.errors} />
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              type="email"
              required
            />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Phone Number"
              type="tel"
            />
            <ValidationError prefix="Phone" field="phone" errors={state.errors} />
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              inputProps={{
                readOnly: window.innerWidth <= 768,
                onTouchStart: (e) => {
                  if (window.innerWidth <= 768) {
                    e.target.readOnly = false;
                  }
                }
              }}
            />
            <ValidationError prefix="Company" field="companyName" errors={state.errors} />
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <TextField
              fullWidth
              id="preferredContact"
              name="preferredContact"
              select
              label="Preferred Contact Method"
              defaultValue=""
            >
              {contactMethods.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <ValidationError prefix="Contact Method" field="preferredContact" errors={state.errors} />
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <TextField
              fullWidth
              id="message"
              name="message"
              label="Message"
              multiline
              rows={5}
              required
              placeholder="Details about your request or requirements"
              inputProps={{
                readOnly: window.innerWidth <= 768,
                onTouchStart: (e) => {
                  if (window.innerWidth <= 768) {
                    e.target.readOnly = false;
                  }
                }
              }}
            />
            <ValidationError prefix="Message" field="message" errors={state.errors} />
          </Grid>

          <Grid item sx={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
              disabled={state.submitting}
            >
              {state.submitting ? 'Sending...' : 'Send'}
            </Button>
          </Grid>
          <Typography
            sx={{
              color: 'rgb(102, 102, 102)',
              mt: 3,
              display: 'flex',
              alignItems: 'center',
              gap: 0.5
            }}
          >
            You can also reach us directly at{' '}
            <a
              href="mailto:support@uniget.com"
              style={{
                color: 'rgb(49, 130, 122)',
                textDecoration: 'none',
                fontWeight: 500
              }}
            >
              support@uniget.com
            </a>
          </Typography>
        </Grid>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ 
          top: '80px !important',
          zIndex: 9999
        }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ 
            width: '100%',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}; 