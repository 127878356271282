import React from 'react';
import { Grid, Typography, Container, Box } from '@mui/material';

export const Terms = () => {
  return (
    <div style={{ margin: '0 auto', padding: '0 20px' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        sx={{ mb: 4 }}
      >
        <Typography
          variant="h4"
          sx={{
            p: 0,
            m: 0,
            fontWeight: 700,
            py: 1,
            textAlign: 'center',
          }}
        >
          Terms of Service
        </Typography>
      </Grid>

      <Container maxWidth="md">
        <Box sx={{ mb: 4 }}>
          <Typography variant="body1" paragraph>
            <strong>Effective Date:</strong> March 14, 2024
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to Uniget ("Company," "we," "us," or "our"). By accessing or using our website, uniget.com (the "Website"), 
            you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Website.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>1. Acceptance of Terms</Typography>
          <Typography variant="body1" paragraph>
            By accessing or using the Website, you confirm that you:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Are at least 18 years old or have the legal capacity to enter into this agreement.</li>
            <li>Agree to comply with these Terms and all applicable laws and regulations.</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>2. Use of the Website</Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>Permitted Use:</Typography>
          <Typography variant="body1" paragraph>
            You may use the Website for personal or business purposes in accordance with these Terms.
          </Typography>
          
          <Typography variant="h6" sx={{ mb: 1 }}>Prohibited Use:</Typography>
          <Typography variant="body1" paragraph>
            You agree not to:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Use the Website for any unlawful purpose.</li>
            <li>Attempt to gain unauthorized access to any part of the Website or its systems.</li>
            <li>Distribute or upload malicious code or software.</li>
            <li>Engage in any activity that disrupts or interferes with the Website's functionality.</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>3. Account Registration</Typography>
          <Typography variant="body1" paragraph>
            To access certain features, you may be required to create an account. You agree to:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Provide accurate and complete information.</li>
            <li>Keep your account credentials secure and confidential.</li>
            <li>Notify us immediately of any unauthorized use of your account.</li>
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to suspend or terminate accounts for violations of these Terms.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>4. Payments</Typography>
          <Typography variant="body1" paragraph>
            If you make purchases through the Website:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>All payments are processed securely via third-party payment processors (e.g., Stripe, PayPal).</li>
            <li>You agree to provide valid payment information.</li>
            <li>Prices and fees are subject to change at our discretion.</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>5. Intellectual Property</Typography>
          <Typography variant="body1" paragraph>
            All content on the Website, including text, graphics, logos, and software, is the property of Uniget or its licensors 
            and is protected by intellectual property laws. You may not:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Copy, distribute, or modify any content without our prior written consent.</li>
            <li>Use our trademarks without authorization.</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>6. Limitation of Liability</Typography>
          <Typography variant="body1" paragraph>
            To the fullest extent permitted by law:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Uniget shall not be liable for any indirect, incidental, or consequential damages arising from your use of the Website.</li>
            <li>Our total liability for any claim arising out of these Terms or your use of the Website is limited to the amount you paid us, if any, during the past 12 months.</li>
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>7. User Content</Typography>
          <Typography variant="body1" paragraph>
            By submitting content to the Website, you grant us a non-exclusive, royalty-free license to use, reproduce, and display your content 
            as necessary to operate the Website. You confirm that:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>You own or have the right to submit the content.</li>
            <li>Your content does not violate any laws or third-party rights.</li>
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to remove content that violates these Terms.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>8. Termination</Typography>
          <Typography variant="body1" paragraph>
            We may terminate or suspend your access to the Website at our discretion, with or without notice, if you violate these Terms 
            or engage in activities that harm the Website or its users.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>9. Third-Party Links</Typography>
          <Typography variant="body1" paragraph>
            The Website may contain links to third-party websites. We are not responsible for the content or practices of these websites 
            and encourage you to review their terms and policies.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>10. Modifications to Terms</Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to update or modify these Terms at any time. Changes will be effective immediately upon posting. 
            Your continued use of the Website constitutes acceptance of the updated Terms.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>11. Governing Law</Typography>
          <Typography variant="body1" paragraph>
            These Terms are governed by the laws of Israel, without regard to its conflict of law principles. Any disputes arising under 
            these Terms shall be resolved in the courts located in Tel Aviv, Israel.
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>12. Contact Information</Typography>
          <Typography variant="body1" paragraph>
            For questions or concerns about these Terms, please contact us:
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>Email: <a href="mailto:support@uniget.com">support@uniget.com</a></li>
            <li>Phone: +972-50-1234567</li>
          </Typography>
        </Box>

        <Box sx={{ mt: 6, mb: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            &copy; {new Date().getFullYear()} Uniget. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}; 