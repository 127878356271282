import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import ScrollAnimation from './ScrollAnimation';
import EmailIcon from '@mui/icons-material/Email';

export default function ContactSection() {
  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="md">
        <ScrollAnimation>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom>
              Let's Talk
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4, fontSize: '1.1rem' }}>
              Have specific requirements or questions? Our team is here to help you find the perfect solution for your needs.
            </Typography>
            <Button
              variant="contained"
              size="large"
              startIcon={<EmailIcon />}
              href='/contact'
              sx={{ 
                px: 4, 
                py: 1.5,
                fontSize: '1.1rem'
              }}
            >
              Contact Us
            </Button>
          </Box>
        </ScrollAnimation>
      </Container>
    </Box>
  );
}
