import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import mixpanel from "mixpanel-browser";

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: "50vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  margin: "0 auto",
}));

const HomeHero = ({ handleGetStarted }) => {
  const handleGetStartedClick = () => {
    mixpanel.track("Hero Get Started Click", {
      location: "Home Hero",
      buttonType: "primary"
    });
    handleGetStarted();
  };

  const handleLearnMoreClick = () => {
    mixpanel.track("Hero Learn More Click", {
      location: "Home Hero",
      buttonType: "secondary"
    });
  };

  return (
    <HeroSection>
      <div style={{ paddingLeft: "30px", width: "100%" }}>
        <Grid
          container
          spacing={4}
          direction={"row"}
          justifyContent="space-between"
          alignItems="flex-end"
          wrap={{ xs: "wrap", md: "nowrap" }}
        >
          <Grid item xs={12} md={6} sx={{ width: "100%" }}>
            <Typography
              style={{ fontSize: 60 }}
              variant="h1"
              component="h1"
              gutterBottom
            >
              <span
                style={{
                  fontSize: 85,
                  fonrWeight: 100,
                  fontFamily: "Playfair Display",
                }}
              >
                Do Less
                <br />
                Achieve{" "}
                <span style={{ 
                  // fontSize: 125, 
                  lineHeight: 1 }}>More.</span>
              </span>
            </Typography>
            <Typography variant="h5" paragraph>
              Chat with a team of expert human assistants{" "}
              <span style={{ marginLeft: "7px" }}>&</span> <br /> professionals
              to improve life & business
              <span> and </span>save time 🕒🧘‍♂️📈
            </Typography>
            <Box mt={4} sx={{ mb: 2 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleGetStartedClick}
                sx={{
                  mr: 2,
                  color: "#ffffff",
                  background: "black",
                }}
              >
                Get Started <ArrowForwardIcon />
              </Button>
              <Button
                variant="outlined"
                size="large"
                href="/about"
                onClick={handleLearnMoreClick}
                sx={{
                  color: "black",
                  borderColor: "black",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                Learn More
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            md={6}
            sx={{ 
              display: { xs: "none", md: "flex" },
              paddingRight: "60px",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img
              src="/landland.svg"
              alt="Hero illustration"
              style={{ maxWidth: "100%", maxHeight: "50%" }}
            />
          </Grid>
        </Grid>
      </div>
    </HeroSection>
  );
};

export default HomeHero;
