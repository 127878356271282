import React, { useState, useCallback, Suspense, lazy } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import servicesData from '../services.json';

// Lazy load the CarouselRow component
const CarouselRow = lazy(() => import('./CarouselRow'));

// Loading skeleton component for the carousel row
const CarouselRowSkeleton = () => (
  <Box sx={{ marginBottom: "32px" }}>
    <Box sx={{ marginBottom: 2 }}>
      <Skeleton variant="text" width={200} height={40} />
    </Box>
    <Box sx={{ display: "flex", gap: 2, overflow: "hidden" }}>
      {[1, 2, 3].map((i) => (
        <Skeleton
          key={i}
          variant="rounded"
          width={280}
          height={200}
          sx={{ flexShrink: 0 }}
        />
      ))}
    </Box>
  </Box>
);

const ServicesComp = ({ onServiceClick }) => {
  return (
    <Box sx={{ padding: "16px", paddingBottom: "100px" }}>
      {servicesData.carousels.map((carousel, index) => (
        <Suspense key={index} fallback={<CarouselRowSkeleton />}>
          <CarouselRow 
            carousel={carousel}
            onServiceClick={onServiceClick}
          />
        </Suspense>
      ))}
    </Box>
  );
};

export default ServicesComp; 