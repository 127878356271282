import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgb(255, 254, 249)'
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography 
          variant="h1" 
          sx={{ 
            fontSize: { xs: '6rem', sm: '8rem' },
            fontWeight: 700,
            color: '#000',
            mb: 2
          }}
        >
          404
        </Typography>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 3,
            color: 'text.primary',
            fontWeight: 500
          }}
        >
          Page Not Found
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 4,
            color: 'text.secondary'
          }}
        >
          The page you're looking for doesn't exist or has been moved.
        </Typography>
        <Button
          variant="contained"
          startIcon={<HomeIcon />}
          onClick={() => navigate('/')}
          sx={{
            bgcolor: '#000',
            color: '#fff',
            px: 4,
            py: 1.5,
            '&:hover': {
              bgcolor: '#333'
            }
          }}
        >
          Back to Home
        </Button>
      </Container>
    </Box>
  );
} 