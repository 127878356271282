import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, TextField, Button, Alert } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import mixpanel from 'mixpanel-browser';

export default function Footer() {
  const footerRef = useRef(null);
  const copyrightRef = useRef(null);

  const handleLinkClick = (name, category) => {
    mixpanel.track('Footer Link Click', {
      location: 'Footer',
      linkName: name,
      category
    });
  };

  const handleSocialClick = (platform) => {
    mixpanel.track('Social Media Click', {
      location: 'Footer',
      platform
    });
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', py: 6, pb: 0 }} ref={footerRef}>
      <Container maxWidth="lg">
        <Grid container wrap="nowrap" justifyContent="space-around">
          <Grid item sx={{ maxWidth: 200 }} >
            <Typography variant="h6" gutterBottom>
              Uniget
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Your on-demand personal assistant for every task.
            </Typography>
            {/* <Box sx={{ mt: 2 }}>
              <IconButton onClick={() => handleSocialClick('Facebook')}><FacebookIcon /></IconButton>
              <IconButton onClick={() => handleSocialClick('Twitter')}><TwitterIcon /></IconButton>
              <IconButton onClick={() => handleSocialClick('LinkedIn')}><LinkedInIcon /></IconButton>
              <IconButton onClick={() => handleSocialClick('Instagram')}><InstagramIcon /></IconButton>
            </Box> */}
          </Grid>
          <Grid item >
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {[
                { name: 'Home', path: '/' },
                { name: 'About', path: '/about' },
                { name: 'Contact', path: '/contact' },
                { name: 'Pricing', path: '/about#pricing' }
              ].map(({ name, path }) => (
                <Link 
                  href={path} 
                  variant="body2" 
                  sx={{ 
                    mb: 0,
                    '&:hover': {
                      textDecoration: 'none',
                      opacity: 0.8
                    }
                  }} 
                  key={name}
                  onClick={() => handleLinkClick(name, 'Quick Links')}
                >
                  {name}
                </Link>
              ))}
            </Box>
          </Grid>
          <Grid item >
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {[
                { name: 'Terms of Service', path: '/terms' },
                { name: 'Privacy Policy', path: '/privacy' },
                { name: 'Cookie Policy', path: '/cookies' }
              ].map(({ name, path }) => (
                <Link 
                  href={path} 
                  variant="body2" 
                  sx={{ 
                    mb: 0,
                    '&:hover': {
                      textDecoration: 'none',
                      opacity: 0.8
                    }
                  }} 
                  key={name}
                  onClick={() => handleLinkClick(name, 'Legal')}
                >
                  {name}
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ my: 2, textAlign: 'center' }}
          ref={copyrightRef}
        >
          © {new Date().getFullYear()} Uniget. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}
