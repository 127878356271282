import React, { useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ScrollAnimation from './ScrollAnimation';
import mixpanel from 'mixpanel-browser';

const benefits = [
  {
    icon: <AccessTimeIcon fontSize="large" color="primary" />,
    title: 'Always Available',
    description: "Round-the-clock support ensures your tasks are handled whenever you need them, across all time zones."
  },
  {
    icon: <VerifiedUserIcon fontSize="large" color="primary" />,
    title: 'Elite Professionals',
    description: "Work with thoroughly vetted experts who bring years of experience and specialized skills to your tasks."
  },
  {
    icon: <AttachMoneyIcon fontSize="large" color="primary" />,
    title: 'Clear Pricing',
    description: "Transparent, task-based pricing with no hidden fees. Only pay for the services you actually use."
  },
  {
    icon: <ThumbUpIcon fontSize="large" color="primary" />,
    title: 'Quality Guaranteed',
    description: "Every task comes with our satisfaction guarantee. Not happy? We'll make it right, no questions asked."
  }
];

export default function Benefits() {
  useEffect(() => {
    mixpanel.track('Benefits Section Viewed', {
      location: 'Benefits Section'
    });
  }, []);

  const handleBenefitClick = (benefitTitle) => {
    mixpanel.track('Benefit Card Clicked', {
      location: 'Benefits Section',
      benefitTitle: benefitTitle
    });
  };

  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="lg">
        <ScrollAnimation>
          <Typography variant="h2" textAlign="center" gutterBottom>
            Why Choose Our On-Demand Assistants?
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {benefits.map((benefit, index) => (
              <Grid 
                item 
                xs={12} 
                md={3} 
                key={index}
                onClick={() => handleBenefitClick(benefit.title)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out'
                  }
                }}
              >
                <Box sx={{ textAlign: 'center' }}>
                  {benefit.icon}
                  <Typography variant="h6" sx={{ my: 2 }}>
                    {benefit.title}
                  </Typography>
                  <Typography variant="body1">
                    {benefit.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </ScrollAnimation>
      </Container>
    </Box>
  );
}
