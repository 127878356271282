import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, firestore } from "../firebase";
import { TopBar } from "./TopBar";
import ChatRoom from "./ChatRoom";
import mixpanel from "mixpanel-browser";
import EarlyAccessDialog from "./EarlyAccessDialog";

const Chat = ({ isAdmin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useAuthState(auth);
  const [url, setUrl] = useState(new URL(window.location.href));
  const [chatId, setChatId] = useState(url.searchParams.get("id") || "");
  const [otherUserId, setOtherUserId] = useState(
    url.searchParams.get("userId") || ""
  );
  const [query, setQuery] = useState(url.searchParams.get("query") || "");
  const [filled, setFilled] = useState(url.searchParams.get("filled") || "");
  const [showEarlyAccessDialog, setShowEarlyAccessDialog] = useState(false);
  const [savedMessage, setSavedMessage] = useState("");
  const chatsRef = firestore.collection("chatRoom");
  const unsubscribeRef = useRef(null);

  // Track initial chat view with proper cleanup
  useEffect(() => {
    let isSubscribed = true;

    const initializeChat = async () => {
      try {
        if (!isSubscribed) return;

        mixpanel.track('Chat View Initialized', {
          location: 'Chat',
          isAuthenticated: !!user,
          isAdmin,
          hasChatId: !!chatId,
          hasQuery: !!query,
          hasOtherUser: !!otherUserId
        });
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    };

    initializeChat();

    return () => {
      isSubscribed = false;
    };
  }, [user, isAdmin, chatId, query, otherUserId]);

  // Track URL parameter changes with proper cleanup
  useEffect(() => {
    let isSubscribed = true;

    const handleUrlChange = async () => {
      try {
        if (!isSubscribed) return;

        const newUrl = new URL(window.location.href);
        const newChatId = newUrl.searchParams.get("id");
        const newOtherUserId = newUrl.searchParams.get("userId");
        const newQuery = newUrl.searchParams.get("query");
        const newFilled = newUrl.searchParams.get("filled");

        if (
          newChatId !== chatId ||
          newOtherUserId !== otherUserId ||
          newQuery !== query ||
          newFilled !== filled
        ) {
          mixpanel.track('Chat Parameters Changed', {
            location: 'Chat',
            changes: {
              chatId: newChatId !== chatId,
              otherUserId: newOtherUserId !== otherUserId,
              query: newQuery !== query,
              filled: newFilled !== filled
            },
            newValues: {
              chatId: newChatId,
              otherUserId: newOtherUserId,
              query: newQuery,
              filled: newFilled
            }
          });

          setUrl(newUrl);
          setChatId(newChatId || "");
          setOtherUserId(newOtherUserId || "");
          setQuery(newQuery || "");
          setFilled(newFilled || "");
        }
      } catch (error) {
        console.error('Error handling URL change:', error);
      }
    };

    handleUrlChange();

    return () => {
      isSubscribed = false;
    };
  }, [window.location.href]);

  // Track early access dialog state
  useEffect(() => {
    if (location.state?.showDialog) {
      mixpanel.track('Early Access Dialog Triggered', {
        location: 'Chat',
        hasSavedMessage: !!location.state.message
      });

      setShowEarlyAccessDialog(true);
      if (location.state.message) {
        setSavedMessage(location.state.message);
      }
    }
  }, [location]);

  // Track saved message changes
  useEffect(() => {
    if (savedMessage) {
      mixpanel.track('Chat Message Saved', {
        location: 'Chat',
        messageLength: savedMessage.length
      });
    }
  }, [savedMessage]);

  // Track authentication state changes with proper cleanup
  useEffect(() => {
    let isSubscribed = true;

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!isSubscribed) return;

      if (user) {
        mixpanel.track('Chat User Authenticated', {
          location: 'Chat',
          userId: user.uid,
          isAdmin
        });
      } else {
        mixpanel.track('Chat User Logged Out', {
          location: 'Chat'
        });
      }
    });

    unsubscribeRef.current = unsubscribe;

    return () => {
      isSubscribed = false;
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [isAdmin]);

  const handleEarlyAccessClose = () => {
    mixpanel.track('Early Access Dialog Closed', {
      location: 'Chat'
    });
    setShowEarlyAccessDialog(false);
  };

  return (
    <div>
      <EarlyAccessDialog 
        open={showEarlyAccessDialog}
        isCloseable={false}
        onClose={handleEarlyAccessClose}
      />

      <Grid
        container
        direction={"column"}
        justifyContent={"stretch"}
        alignItems={"center"}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              position: "fixed",
              top: 0,
              zIndex: 999999,
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "block",
                margin: "auto",
              }}
            >
              <TopBar tab="chats" isAdmin={isAdmin} />
            </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <section>
            <ChatRoom
              chatId={chatId}
              defaultQuery={savedMessage || query}
              otherUserId={otherUserId}
            />
          </section>
        </div>
      </Grid>
    </div>
  );
};

export default Chat;
