import React from 'react';
import { Box, Button, Container, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { motion } from 'framer-motion';
import '../styles/animations.css';
import { useNavigate } from 'react-router-dom';

const quickBenefits = [
  'Available 24/7 - Your tasks handled anytime, anywhere',
  'Elite Professionals - Thoroughly vetted experts in their fields',
  'Simple Pricing - Pay only for what you need, with full transparency'
];

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const slideIn = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.8 }
};

export default function Hero({ onOpenAuth }) {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    onOpenAuth('signup');
  };

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
          <Box sx={{ flex: 1, minWidth: { xs: '100%', md: '50%' } }}>
            <motion.div {...fadeIn}>
              <Box>
                <Typography variant="h1" gutterBottom>
                  Your Life and Business, Simplified.
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 4, fontSize: '1.2rem' }}>
                  From daily tasks to complex projects, our expert assistants are here to give you back your most valuable asset - time.
                </Typography>
              </Box>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.6 }}
            >
              <List sx={{ mb: 4 }}>
                {quickBenefits.map((benefit, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.5 + index * 0.2 }}
                  >
                    <ListItem sx={{ p: 0, mb: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36, color: 'primary.main' }}>
                        <CheckCircleOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  </motion.div>
                ))}
              </List>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.2, duration: 0.6 }}
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleGetStarted}
                  sx={{ px: 6, py: 1.5, mx: 'auto' }}
                >
                  Get Started
                </Button>
              </Box>
            </motion.div>
          </Box>

          <Box 
            sx={{ 
              flex: 1, 
              minWidth: { xs: '100%', md: '50%' }, 
              position: 'relative',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <motion.div {...slideIn} style={{ position: 'relative' }}>
              <Box
                component="img"
                src="/assets/hero-main.svg"
                alt="Personal Assistant"
                sx={{
                  width: '100%',
                  maxWidth: 400,
                  height: 'auto',
                  filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)'
                  }
                }}
              />
              <Box
                component={motion.img}
                src="/assets/chat-bubble.svg"
                alt="Chat Demo"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1, duration: 0.5 }}
                sx={{
                  position: 'absolute',
                  bottom: '10%',
                  right: '10%',
                  width: 100,
                  height: 100,
                  filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15))',
                  animation: 'float 3s ease-in-out infinite',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  }
                }}
              />
            </motion.div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
