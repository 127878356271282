import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDMLHGNFMgPgUDzuNPRRmdEw13Mo2NW9qE",
  authDomain: "ultimate-2c4e3.firebaseapp.com",
  databaseURL: "https://ultimate-2c4e3.firebaseio.com",
  projectId: "ultimate-2c4e3",
  storageBucket: "ultimate-2c4e3.appspot.com",
  messagingSenderId: "476760652743",
  appId: "1:476760652743:web:361fcbaf503c0f842e77e2",
  measurementId: "G-T7HS5R4D2N",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export the services we'll use
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();

export default firebase; 