import React, { Fragment, useEffect, useMemo, useRef, useState, useCallback } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopy from "@mui/icons-material/ContentCopy";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import mixpanel from "mixpanel-browser";
import ServicesComp from './ServicesComp';
import { auth, firestore } from '../firebase';
import EarlyAccessDialog from './EarlyAccessDialog';
import HomeHero from './HomeHero';
import Footer from './Footer';

// Memoize TabPanel for better performance
const TabPanel = React.memo(function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{
        padding: 10,
        maxHeight: "calc(100vh - 160px)",
        background: "rgb(255, 254, 249)",
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
});

// Memoize ProgressBar component
const ProgressBar = React.memo(({ color, hex, value }) => {
  let internalColor;

  if (typeof hex === "undefined") {
    internalColor = "#3f51b5";
  } else if (typeof hex !== "undefined" && /^#[0-9A-F]{6}$/i.test(hex)) {
    internalColor = hex;
  } else {
    throw new Error("Invalid hex prop -- please use a hex string.");
  }

  if (typeof value === "undefined") {
    value = 0;
  } else if (typeof value === "number" && value < 0) {
    throw new Error(
      "Invalid value prop -- please use a number more than or equal to 0."
    );
  } else if (typeof value === "number" && value > 100) {
    throw new Error(
      "Invalid value prop -- please use a number less than or equal to 100."
    );
  }

  return (
    <Box position="relative" display="inline-flex" style={{ width: "100%" }}>
      <LinearProgress
        style={{
          height: 25,
          width: "100%",
          backgroundColor: hex ? `${internalColor} 50%` : undefined,
          borderRadius: "10px",
        }}
        color={hex ? undefined : color}
        variant="determinate"
        value={value}
      />
      <Box
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="body2"
          style={{ color: "#FFFFFF" }}
        >{`${value}%`}</Typography>
      </Box>
    </Box>
  );
});

// Memoize PromoteInLineCard component
const PromoteInLineCard = React.memo(() => {
  const [user] = useAuthState(auth);
  const [url, setUrl] = useState(() => `https://uniget.com/invite/`);

  useEffect(() => {
    if (!user) return;
    setUrl(`https://uniget.com/invite/${user.uid}`);
  }, [user]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  return (
    <Card
      style={{
        margin: "auto",
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <CardContent>
        <Typography variant="h5" component="h2" align="center">
          Congratulations, You are on the List! 🚀
        </Typography>
        <Typography variant="body1" align="center" style={{ marginBottom: 20 }}>
          Thank you for joining our waiting list. We are excited to have you on
          board. Once you have been accepted, we will send you an email to the
          address you provided.
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5">Move up the waiting list ☝️</Typography>
        </Grid>

        <List
          dense
          sx={{
            maxWidth: "500px",
          }}
        >
          <div
            style={{
              padding: "10px 10px 30px 0px",
            }}
          >
            <ListItem
              disablePadding
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <Typography sx={{ m: 0, p: 0 }}>
                Specify reasons would find you for{" "}
              </Typography>
              <ArrowRightIcon sx={{ p: 0, m: 0 }} />
              <Typography sx={{ m: 0, p: 0 }}>40%</Typography>
            </ListItem>
            <Button variant="outlined">Add Contact Reasons +</Button>
            <Divider sx={{ marginTop: "10px" }} />
          </div>
          <div
            style={{
              padding: "0px 10px 30px 0px",
            }}
          >
            <ListItem
              disablePadding
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <Typography sx={{ m: 0, p: 0 }}>
                Specify type of services and people you are looking for
              </Typography>
              <ArrowRightIcon sx={{ p: 0, m: 0 }} />
              <Typography sx={{ m: 0, p: 0 }}>40%</Typography>
            </ListItem>
            <Button variant="outlined">Specify your needs +</Button>
            <Divider sx={{ marginTop: "10px" }} />
          </div>
          <div
            style={{
              padding: "0px 10px 30px 0px",
            }}
          >
            <ListItem
              disablePadding
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <Typography sx={{ m: 0, p: 0 }}>
                Refer friends and promote you and them on waiting list
              </Typography>
              <ArrowRightIcon sx={{ p: 0, m: 0 }} />
              <Typography sx={{ m: 0, p: 0 }}>20%</Typography>
            </ListItem>
            <Box display="flex" alignItems="center" gap={1}>
              <TextField
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                variant="outlined"
                fullWidth
                disabled
              />
              <IconButton onClick={handleCopy}>
                <ContentCopy />
              </IconButton>
              <TwitterShareButton url={url}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={url}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <LinkedinShareButton url={url}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <FacebookShareButton url={url}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </Box>
            <Divider sx={{ marginTop: "10px" }} />
          </div>

          <div sx={{ marginTop: "40px" }}>
            <ProgressBar color="primary" value={0} />
            <Typography
              variant="caption"
              sx={{
                position: "relative",
                top: "-10px",
              }}
            >
              Completness of steps to move up the waiting list (%)
            </Typography>
          </div>
        </List>
      </CardContent>
    </Card>
  );
});

// New RequestInput component for better performance
const RequestInput = React.memo(({ onSubmit }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if ((event.code === "Enter" || event.code === "NumpadEnter") && inputValue.trim()) {
      onSubmit(inputValue);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim()) {
      onSubmit(inputValue);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      style={{ width: "100%", marginBottom: 20, marginTop: 20 }}
    >
      <div
        style={{
          width: "100%",
          padding: 0,
          maxWidth: 668,
          display: "flex",
          
        }}
      >
        <TextField
          inputRef={inputRef}
          fullWidth
          placeholder="Enter custom request..."
          variant="outlined"
          value={inputValue}
          autoFocus={window.innerWidth > 768}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          sx={{
            position: "relative",
            top: "0.5px",
            height: "52px",
            width: { xs: "calc(100% - 80px)", sm: "calc(100% - 110px)" },
            "& .MuiOutlinedInput-root": {
              height: "56px",
              "& fieldset": {
                borderColor: "#000000",
                borderRadius: { xs: "4px 0 0 4px", sm: "4px 0 0 4px" },
                borderRight: "none"
              },
              "&:hover fieldset": {
                borderColor: "#000000"
              },
              "&.Mui-focused fieldset": {
                borderColor: "#000000"
              }
            }
          }}
          autoComplete="off"
          type="text"
          inputProps={{
            readOnly: window.innerWidth <= 768,
            onTouchStart: (e) => {
              if (window.innerWidth <= 768) {
                e.target.readOnly = false;
              }
            }
          }}
          focused
        />
        <Button
          onClick={handleSubmit}
          variant="contained"
          disableElevation
          sx={{
            background: "#000000",
            // borderTop: "5px solid #000000",
            height: "56.5px",
            minWidth: { xs: "80px", sm: "110px" },
            borderRadius: { xs: "0 4px 4px 0", sm: "0 4px 4px 0" },
            "&:hover": {
              background: "#333333"
            },
            padding: 0,
            marginLeft: "-1px"
          }}
        >
          SEND
        </Button>
      </div>
    </Grid>
  );
});

function Home({ tab, isAdmin, onShowDialog }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Memoize tab value calculation
  const [value, setValue] = useState(() => 
    tab === "chats" ? 1 : tab === "requests" ? 2 : tab === "profile" ? 3 : 0
  );

  const [selectedService, setSelectedService] = useState(null);

  // Memoize tab value update
  useEffect(() => {
    setValue(
      tab === "new"
        ? 0
        : tab === "chats"
        ? 1
        : tab === "requests"
        ? 2
        : tab === "profile"
        ? 3
        : -1
    );
  }, [tab]);

  const openWaitingListFunc = useCallback((service = null) => {
    const params = service ? `?service=${encodeURIComponent(service.name)}` : '';
    navigate(`/chat${params}`, { state: { showDialog: true, service } });
  }, [navigate]);

  const handleRequestSubmit = useCallback((value) => {
    const params = `?message=${encodeURIComponent(value)}`;
    navigate(`/chat${params}`, { state: { showDialog: true, message: value } });
  }, [navigate]);

  const handleGetStarted = useCallback(() => {
    onShowDialog(0); // Show signup tab
  }, [onShowDialog]);

  // Get dialog state from URL
  const searchParams = new URLSearchParams(location.search);
  const authMode = searchParams.get('auth');
  const isDialogOpen = authMode === 'signup' || authMode === 'login';

  // Memoize the main content to prevent unnecessary re-renders
  const mainContent = useMemo(() => (
    <div style={{ paddingBottom: 120, height: "calc(100vh - 300px)" }}>
      <TabPanel
        value={value}
        index={0}
        style={{
          margin: 0,
          padding: 0,
          backgroundColor: "rgb(255, 254, 249)",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 1540,
            margin: "auto",
          }}
        >
          <div
            style={{
              margin: "auto",
            }}
          >
            {user && user?.uid && (
              <div
                style={{
                  paddingTop: "50px",
                }}
              >
                <PromoteInLineCard />
              </div>
            )}
            <div style={{ width: "100%", margin: 0 }}>
              <div style={{ width: "100%", margin: 0 }}>
                <HomeHero handleGetStarted={handleGetStarted} />
                <ServicesComp onServiceClick={openWaitingListFunc} />
                <div style={{ height: "10px" }} />
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      {location.pathname.includes("/") && (
        <Paper
          elevation={0}
          sx={{
            zIndex: 999,
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "rgb(255, 254, 249)",
            padding: "0 15px",
          }}
        >
          <RequestInput onSubmit={handleRequestSubmit} />
        </Paper>
      )}
    </div>
  ), [value, isDialogOpen, user, handleGetStarted, handleRequestSubmit, location.pathname, selectedService, openWaitingListFunc]);

  return mainContent;
}

export default React.memo(Home); 