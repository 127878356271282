import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const SignIn = ({ text, variant }) => {
  const [isSignedIn, setIsSignedIn] = useState(-1);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider);
  };

  if (isSignedIn === -1) {
    return null;
  }

  return (
    <Button
      sx={{ py: "5px" }}
      size="small"
      variant={variant || "contained"}
      className="sign-in"
      onClick={signInWithGoogle}
    >
      {text ? text : "Get Early Access"}
    </Button>
  );
}; 