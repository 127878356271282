import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import ScrollAnimation from './ScrollAnimation';
import EarlyAccessDialog from './EarlyAccessDialog';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

export default function RequestForm() {
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track('Request Form Section Viewed', {
      location: 'Request Form Section'
    });
  }, []);

  const handleGetStarted = () => {
    mixpanel.track('Request Form Get Started Click', {
      location: 'Request Form Section',
      buttonType: 'primary'
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('auth', 'signup');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  // Get dialog state from URL
  const searchParams = new URLSearchParams(window.location.search);
  const authMode = searchParams.get('auth');
  const isDialogOpen = authMode === 'signup' || authMode === 'login';

  const handleDialogClose = () => {
    mixpanel.track('Request Form Dialog Closed', {
      location: 'Request Form Section'
    });
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('auth');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="md">
        <ScrollAnimation>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom>
              Transform Your Productivity
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4, fontSize: '1.1rem' }}>
              Join individuals and teams already using Uniget to streamline their workflow and boost productivity by up to 10x.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={handleGetStarted}
              sx={{ 
                px: 6, 
                py: 1.5,
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out'
                }
              }}
            >
              Get Started
            </Button>
          </Box>
        </ScrollAnimation>
      </Container>

      <EarlyAccessDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        isCloseable={true}
        initialTab={0}
      />
    </Box>
  );
}
