import React, { useEffect, useRef } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskIcon from '@mui/icons-material/Task';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ScrollAnimation from './ScrollAnimation';
import mixpanel from 'mixpanel-browser';

const metrics = [
  {
    icon: <AccessTimeIcon fontSize="large" color="primary" />,
    title: 'Save Time',
    description: 'Get hours back every week to focus on your priorities'
  },
  {
    icon: <TaskIcon fontSize="large" color="primary" />,
    title: 'Simplify Your Life',
    description: 'Effortlessly delegate tasks and eliminate stress'
  },
  {
    icon: <ThumbUpIcon fontSize="large" color="primary" />,
    title: 'Boost Productivity',
    description: 'Achieve more by letting us handle the details'
  },
  {
    icon: <LightbulbIcon fontSize="large" color="primary" />,
    title: 'More Mental Space',
    description: 'Free your mind for creativity and important decisions'
  }
];

function Metrics() {
  const sectionRef = useRef(null);
  const metricsTracked = useRef({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const metricTitle = entry.target.getAttribute('data-metric-title');
          if (entry.isIntersecting && !metricsTracked.current[metricTitle]) {
            metricsTracked.current[metricTitle] = true;
          }
        });
      },
      {
        threshold: 0.5,
        root: null,
        rootMargin: '0px'
      }
    );

    const metricElements = document.querySelectorAll('[data-metric-title]');
    metricElements.forEach(element => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleMetricClick = (metricTitle) => {
    mixpanel.track('Metric Card Click', {
      location: 'Metrics Section',
      metricTitle
    });
  };

  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }} ref={sectionRef}>
      <Container maxWidth="lg">
        <ScrollAnimation>
          <Typography variant="h2" textAlign="center" gutterBottom>
            Unlock Your Full Potential
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {metrics.map((metric, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box 
                  sx={{ 
                    textAlign: 'center', 
                    px: 2,
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      transition: 'transform 0.2s ease-in-out'
                    }
                  }}
                  onClick={() => handleMetricClick(metric.title)}
                  data-metric-title={metric.title}
                >
                  {metric.icon}
                  <Typography variant="h5" sx={{ my: 2, fontWeight: 'bold' }}>
                    {metric.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {metric.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </ScrollAnimation>
      </Container>
    </Box>
  );
}

export default Metrics;
