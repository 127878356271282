import React from 'react';
import { Element } from 'react-scroll';
import Navbar from './Navbar';
import Hero from './Hero';
import Benefits from './Benefits';
import HowItWorks from './HowItWorks';
import Services from './Services';
import Pricing from './Pricing';
import Metrics from './Metrics';
import RequestForm from './RequestForm';
import FAQ from './FAQ';
import ContactSection from './ContactSection';
import Footer from './Footer';
import EarlyAccessDialog from './EarlyAccessDialog';
import { useAuthDialog } from '../hooks/useAuthDialog';

export default function About() {
  const { isOpen, activeTab, openDialog, closeDialog, setActiveTab } = useAuthDialog();

  return (
    <>
      <EarlyAccessDialog
        open={isOpen}
        onClose={closeDialog}
        initialTab={activeTab}
        onTabChange={(newTab) => setActiveTab(newTab)}
      />
      <Element name="hero" id="hero">
        <Hero onOpenAuth={openDialog} />
      </Element>
      <Element name="benefits" id="benefits">
        <Benefits onOpenAuth={openDialog} />
      </Element>
      <Element name="how-it-works" id="how-it-works">
        <HowItWorks onOpenAuth={openDialog} />
      </Element>
      <Element name="services" id="services">
        <Services onOpenAuth={openDialog} />
      </Element>
      <Element name="pricing" id="pricing">
        <Pricing onOpenAuth={openDialog} />
      </Element>
      <Element name="metrics" id="metrics">
        <Metrics onOpenAuth={openDialog} />
      </Element>
      <Element name="request" id="request">
        <RequestForm onOpenAuth={openDialog} />
      </Element>
      <Element name="faq" id="faq">
        <FAQ onOpenAuth={openDialog} />
      </Element>
      <Element name="contact" id="contact">
        <ContactSection onOpenAuth={openDialog} />
      </Element>
    </>
  );
}
