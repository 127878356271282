import { TextField, Button } from '@mui/material';

const MessageInput = ({
  value,
  onChange,
  onSubmit,
  onKeyDown,
  placeholder = "Enter your message...",
  inputRef,
  autoFocus = true,
  disabled = false,
  onFocus,
  onBlur,
}) => {
  return (
    <div
      style={{
        width: "100%",
        padding: 0,
        maxWidth: 668,
        display: "flex",
        gap: 0,
      }}
    >
      <TextField
        inputRef={inputRef}
        fullWidth
        placeholder={placeholder}
        variant="outlined"
        value={value}
        autoFocus={autoFocus && window.innerWidth > 768}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        sx={{
          position: "relative",
          top: "0.5px",
          height: "52px",
          width: { xs: "calc(100% - 64px)", sm: "calc(100% - 110px)" },
          "& .MuiOutlinedInput-root": {
            height: "56px",
            "& fieldset": {
              borderColor: "#000000",
              borderRadius: { xs: "4px 0 0 4px", sm: "4px 0 0 4px" },
              borderRight: "none"
            },
            "&:hover fieldset": {
              borderColor: "#000000"
            },
            "&.Mui-focused fieldset": {
              borderColor: "#000000"
            }
          }
        }}
        autoComplete="off"
        type="text"
        inputProps={{ 
          autoComplete: "off",
          style: { fontSize: { xs: "14px", sm: "16px" } },
          readOnly: window.innerWidth <= 768,
          onTouchStart: (e) => {
            if (window.innerWidth <= 768) {
              e.target.readOnly = false;
            }
          }
        }}
        focused
      />
      <Button
        onClick={onSubmit}
        variant="contained"
        disableElevation
        // disabled={disabled}
        type="submit"
        sx={{
          background: disabled ? "#666666" : "#000000",
          height: "56.5px",
          minWidth: { xs: "64px", sm: "110px" },
          borderRadius: { xs: "0 4px 4px 0", sm: "0 4px 4px 0" },
          "&:hover": {
            background: disabled ? "#666666" : "#333333"
          },
          "&.Mui-disabled": {
            background: "#666666",
            color: "rgba(255, 255, 255, 0.8)"
          },
          padding: 0,
          marginLeft: "-1px",
          fontSize: { xs: "14px", sm: "16px" }
        }}
      >
        SEND
      </Button>
    </div>
  );
};

export default MessageInput; 