export const languageOptions = [
  { name: "Afrikaans", native: "Afrikaans", search: "Afrikaans" },
  { name: "Albanian", native: "Shqip", search: "Albanian Shqip" },
  { name: "Amharic", native: "አማርኛ", search: "Amharic አማርኛ" },
  { name: "Arabic", native: "العربية", search: "Arabic العربية" },
  { name: "Armenian", native: "Հայերեն", search: "Armenian Հայերեն" },
  { name: "Assamese", native: "অসমীয়া", search: "Assamese অসমীয়া" },
  { name: "Azerbaijani", native: "Azərbaycan", search: "Azerbaijani Azərbaycan" },
  { name: "Basque", native: "Euskara", search: "Basque Euskara" },
  { name: "Belarusian", native: "Беларуская", search: "Belarusian Беларуская" },
  { name: "Bengali", native: "বাংলা", search: "Bengali Bangla বাংলা" },
  { name: "Bhojpuri", native: "भोजपुरी", search: "Bhojpuri भोजपुरी" },
  { name: "Bosnian", native: "Bosanski", search: "Bosnian Bosanski" },
  { name: "Bulgarian", native: "Български", search: "Bulgarian Български" },
  { name: "Burmese", native: "မြန်မာဘာသာ", search: "Burmese Myanmar မြန်မာဘာသာ" },
  { name: "Catalan", native: "Català", search: "Catalan Català" },
  { name: "Cebuano", native: "Cebuano", search: "Cebuano" },
  { name: "Chichewa", native: "Chichewa", search: "Chichewa Nyanja" },
  { name: "Chinese (Simplified)", native: "简体中文", search: "Chinese Simplified 简体中文 Zhongwen" },
  { name: "Chinese (Traditional)", native: "繁體中文", search: "Chinese Traditional 繁體中文 Zhongwen" },
  { name: "Croatian", native: "Hrvatski", search: "Croatian Hrvatski" },
  { name: "Czech", native: "Čeština", search: "Czech Čeština Cestina" },
  { name: "Danish", native: "Dansk", search: "Danish Dansk" },
  { name: "Dutch", native: "Nederlands", search: "Dutch Nederlands" },
  { name: "English", native: "English", search: "English" },
  { name: "Estonian", native: "Eesti", search: "Estonian Eesti" },
  { name: "Filipino", native: "Filipino", search: "Filipino Tagalog" },
  { name: "Finnish", native: "Suomi", search: "Finnish Suomi" },
  { name: "French", native: "Français", search: "French Français Francais" },
  { name: "Galician", native: "Galego", search: "Galician Galego" },
  { name: "Georgian", native: "ქართული", search: "Georgian ქართული" },
  { name: "German", native: "Deutsch", search: "German Deutsch" },
  { name: "Greek", native: "Ελληνικά", search: "Greek Ελληνικά" },
  { name: "Gujarati", native: "ગુજરાતી", search: "Gujarati ગુજરાતી" },
  { name: "Haitian Creole", native: "Kreyòl Ayisyen", search: "Haitian Creole Kreyòl Ayisyen" },
  { name: "Hausa", native: "Hausa", search: "Hausa" },
  { name: "Hebrew", native: "עברית", search: "Hebrew עברית" },
  { name: "Hindi", native: "हिन्दी", search: "Hindi हिन्दी" },
  { name: "Hmong", native: "Hmoob", search: "Hmong Hmoob" },
  { name: "Hungarian", native: "Magyar", search: "Hungarian Magyar" },
  { name: "Icelandic", native: "Íslenska", search: "Icelandic Íslenska" },
  { name: "Igbo", native: "Igbo", search: "Igbo" },
  { name: "Indonesian", native: "Bahasa Indonesia", search: "Indonesian Bahasa Indonesia" },
  { name: "Irish", native: "Gaeilge", search: "Irish Gaeilge" },
  { name: "Italian", native: "Italiano", search: "Italian Italiano" },
  { name: "Japanese", native: "日本語", search: "Japanese 日本語 Nihongo" },
  { name: "Javanese", native: "Basa Jawa", search: "Javanese Basa Jawa" },
  { name: "Kannada", native: "ಕನ್ನಡ", search: "Kannada ಕನ್ನಡ" },
  { name: "Kazakh", native: "Қазақ", search: "Kazakh Қазақ" },
  { name: "Khmer", native: "ខ្មែរ", search: "Khmer ខ្មែរ" },
  { name: "Korean", native: "한국어", search: "Korean 한국어" },
  { name: "Kurdish", native: "Kurdî", search: "Kurdish Kurdî" },
  { name: "Kyrgyz", native: "Кыргызча", search: "Kyrgyz Кыргызча" },
  { name: "Lao", native: "ລາວ", search: "Lao ລາວ" },
  { name: "Latin", native: "Latina", search: "Latin Latina" },
  { name: "Latvian", native: "Latviešu", search: "Latvian Latviešu" },
  { name: "Lithuanian", native: "Lietuvių", search: "Lithuanian Lietuvių" },
  { name: "Luxembourgish", native: "Lëtzebuergesch", search: "Luxembourgish Lëtzebuergesch" },
  { name: "Macedonian", native: "Македонски", search: "Macedonian Македонски" },
  { name: "Malagasy", native: "Malagasy", search: "Malagasy" },
  { name: "Malay", native: "Bahasa Melayu", search: "Malay Bahasa Melayu" },
  { name: "Malayalam", native: "മലയാളം", search: "Malayalam മലയാളം" },
  { name: "Maltese", native: "Malti", search: "Maltese Malti" },
  { name: "Maori", native: "Māori", search: "Maori Māori" },
  { name: "Marathi", native: "मराठी", search: "Marathi मराठी" },
  { name: "Mongolian", native: "Монгол", search: "Mongolian Монгол" },
  { name: "Nepali", native: "नेपाली", search: "Nepali नेपाली" },
  { name: "Norwegian", native: "Norsk", search: "Norwegian Norsk" },
  { name: "Odia", native: "ଓଡ଼ିଆ", search: "Odia ଓଡ଼ିଆ" },
  { name: "Pashto", native: "پښتو", search: "Pashto پښتو" },
  { name: "Persian", native: "فارسی", search: "Persian Farsi فارسی" },
  { name: "Polish", native: "Polski", search: "Polish Polski" },
  { name: "Portuguese", native: "Português", search: "Portuguese Português" },
  { name: "Punjabi", native: "ਪੰਜਾਬੀ", search: "Punjabi ਪੰਜਾਬੀ" },
  { name: "Romanian", native: "Română", search: "Romanian Română" },
  { name: "Russian", native: "Русский", search: "Russian Русский" },
  { name: "Samoan", native: "Gagana Samoa", search: "Samoan Gagana Samoa" },
  { name: "Serbian", native: "Српски", search: "Serbian Српски" },
  { name: "Shona", native: "chiShona", search: "Shona chiShona" },
  { name: "Sindhi", native: "سنڌي", search: "Sindhi سنڌي" },
  { name: "Sinhala", native: "සිංහල", search: "Sinhala සිංහල" },
  { name: "Slovak", native: "Slovenčina", search: "Slovak Slovenčina" },
  { name: "Slovenian", native: "Slovenščina", search: "Slovenian Slovenščina" },
  { name: "Somali", native: "Soomaali", search: "Somali Soomaali" },
  { name: "Spanish", native: "Español", search: "Spanish Español Espanol" },
  { name: "Sundanese", native: "Basa Sunda", search: "Sundanese Basa Sunda" },
  { name: "Swahili", native: "Kiswahili", search: "Swahili Kiswahili" },
  { name: "Swedish", native: "Svenska", search: "Swedish Svenska" },
  { name: "Tajik", native: "Тоҷикӣ", search: "Tajik Тоҷикӣ" },
  { name: "Tamil", native: "தமிழ்", search: "Tamil தமிழ்" },
  { name: "Telugu", native: "తెలుగు", search: "Telugu తెలుగు" },
  { name: "Thai", native: "ไทย", search: "Thai ไทย" },
  { name: "Turkish", native: "Türkçe", search: "Turkish Türkçe" },
  { name: "Ukrainian", native: "Українська", search: "Ukrainian Українська" },
  { name: "Urdu", native: "اردو", search: "Urdu اردو" },
  { name: "Uzbek", native: "O'zbek", search: "Uzbek O'zbek" },
  { name: "Vietnamese", native: "Tiếng Việt", search: "Vietnamese Tiếng Việt" },
  { name: "Welsh", native: "Cymraeg", search: "Welsh Cymraeg" },
  { name: "Xhosa", native: "isiXhosa", search: "Xhosa isiXhosa" },
  { name: "Yiddish", native: "ייִדיש", search: "Yiddish ייִדיש" },
  { name: "Yoruba", native: "Yorùbá", search: "Yoruba Yorùbá" },
  { name: "Zulu", native: "isiZulu", search: "Zulu isiZulu" },
  { name: "Akan", native: "Akan", search: "Akan Twi" },
  { name: "Bambara", native: "Bamanankan", search: "Bambara Bamanankan" },
  { name: "Corsican", native: "Corsu", search: "Corsican Corsu" },
  { name: "Esperanto", native: "Esperanto", search: "Esperanto" },
  { name: "Frisian", native: "Frysk", search: "Frisian Frysk" },
  { name: "Fulah", native: "Fulfulde", search: "Fulah Fulfulde Fula" },
  { name: "Hawaiian", native: "ʻŌlelo Hawaiʻi", search: "Hawaiian Olelo" },
  { name: "Kashmiri", native: "कॉशुर", search: "Kashmiri कॉशुर" },
  { name: "Kinyarwanda", native: "Ikinyarwanda", search: "Kinyarwanda" },
  { name: "Konkani", native: "कोंकणी", search: "Konkani कोंकणी" },
  { name: "Lingala", native: "Lingála", search: "Lingala" },
  { name: "Manipuri", native: "মৈতৈলোন্", search: "Manipuri Meiteilon মৈতৈলোন্" },
  { name: "Oromo", native: "Afaan Oromoo", search: "Oromo Afaan" },
  { name: "Sanskrit", native: "संस्कृतम्", search: "Sanskrit संस्कृतम्" },
  { name: "Santali", native: "ᱥᱟᱱᱛᱟᱲᱤ", search: "Santali ᱥᱟᱱᱛᱟᱲᱤ" },
  { name: "Scots Gaelic", native: "Gàidhlig", search: "Scots Gaelic Gàidhlig Scottish" },
  { name: "Sesotho", native: "Sesotho", search: "Sesotho Sotho" },
  { name: "Tatar", native: "Татар", search: "Tatar Татар" },
  { name: "Tigrinya", native: "ትግርኛ", search: "Tigrinya ትግርኛ" },
  { name: "Turkmen", native: "Türkmen", search: "Turkmen Türkmen" },
  { name: "Uyghur", native: "ئۇيغۇرچە", search: "Uyghur Uighur ئۇيغۇرچە" },
  { name: "Wolof", native: "Wolof", search: "Wolof" },
  { name: "Aragonese", native: "Aragonés", search: "Aragonese Aragonés" },
  { name: "Assyrian", native: "ܣܘܪܝܬ", search: "Assyrian Syriac ܣܘܪܝܬ" },
  { name: "Balinese", native: "ᬩᬲᬩᬮᬶ", search: "Balinese Basa Bali ᬩᬲᬩᬮᬶ" },
  { name: "Breton", native: "Brezhoneg", search: "Breton Brezhoneg" },
  { name: "Cantonese", native: "廣東話", search: "Cantonese Yue 廣東話 粤语" },
  { name: "Faroese", native: "Føroyskt", search: "Faroese Føroyskt" },
  { name: "Greenlandic", native: "Kalaallisut", search: "Greenlandic Kalaallisut" },
  { name: "Guarani", native: "Avañe'ẽ", search: "Guarani Avañe'ẽ" },
  { name: "Hakka", native: "客家話", search: "Hakka Chinese 客家話" },
  { name: "Interlingua", native: "Interlingua", search: "Interlingua" },
  { name: "Kabyle", native: "Taqbaylit", search: "Kabyle Taqbaylit" },
  { name: "Ladino", native: "Judeo-español", search: "Ladino Judeo-español" },
  { name: "Low German", native: "Plattdüütsch", search: "Low German Plattdüütsch" },
  { name: "Min Nan", native: "閩南語", search: "Min Nan Hokkien 閩南語" },
  { name: "Nahuatl", native: "Nāhuatl", search: "Nahuatl Aztec Nāhuatl" },
  { name: "Occitan", native: "Occitan", search: "Occitan Lenga d'òc" },
  { name: "Quechua", native: "Runa Simi", search: "Quechua Runa Simi" },
  { name: "Romansh", native: "Rumantsch", search: "Romansh Rumantsch" },
  { name: "Sami", native: "Sámegiella", search: "Sami Sámegiella" },
  { name: "Sicilian", native: "Sicilianu", search: "Sicilian Sicilianu" },
  { name: "Tibetan", native: "བོད་སྐད་", search: "Tibetan བོད་སྐད་" },
  { name: "Wu", native: "吴语", search: "Wu Chinese 吴语" },
  { name: "Ainu", native: "アイヌ・イタㇰ", search: "Ainu アイヌ・イタㇰ" },
  { name: "Alsatian", native: "Elsässisch", search: "Alsatian Elsässisch" },
  { name: "Chechen", native: "Нохчийн", search: "Chechen Нохчийн" },
  { name: "Cornish", native: "Kernewek", search: "Cornish Kernewek" },
  { name: "Flemish", native: "Vlaams", search: "Flemish Vlaams Dutch" },
  { name: "Friulian", native: "Furlan", search: "Friulian Furlan" },
  { name: "Gan", native: "贛語", search: "Gan Chinese 贛語" },
  { name: "Jin", native: "晋语", search: "Jin Chinese 晋语" },
  { name: "Kashubian", native: "Kaszëbsczi", search: "Kashubian Kaszëbsczi" },
  { name: "Limburgish", native: "Limburgs", search: "Limburgish Limburgs" },
  { name: "Manx", native: "Gaelg", search: "Manx Gaelg" },
  { name: "Mirandese", native: "Mirandés", search: "Mirandese Mirandés" },
  { name: "Navajo", native: "Diné bizaad", search: "Navajo Diné bizaad" },
  { name: "Neapolitan", native: "Napulitano", search: "Neapolitan Napulitano" },
  { name: "Norman", native: "Normaund", search: "Norman Normaund" },
  { name: "Ossetian", native: "Ирон", search: "Ossetian Ирон" },
  { name: "Piedmontese", native: "Piemontèis", search: "Piedmontese Piemontèis" },
  { name: "Rusyn", native: "Русиньскый", search: "Rusyn Русиньскый" },
  { name: "Sardinian", native: "Sardu", search: "Sardinian Sardu" },
  { name: "Sorbian", native: "Serbsce", search: "Sorbian Serbsce" },
  { name: "Sylheti", native: "ꠍꠤꠟꠐꠤ", search: "Sylheti ꠍꠤꠟꠐꠤ" },
  { name: "Venetian", native: "Vèneto", search: "Venetian Vèneto" },
  { name: "Xiang", native: "湘语", search: "Xiang Chinese 湘语" },
  { name: "Abkhaz", native: "Аԥсуа", search: "Abkhaz Аԥсуа" },
  { name: "Acehnese", native: "Acèh", search: "Acehnese Aceh Acèh" },
  { name: "Adyghe", native: "Адыгэбзэ", search: "Adyghe Адыгэбзэ" },
  { name: "Balochi", native: "بلوچی", search: "Balochi بلوچی" },
  { name: "Bashkir", native: "Башҡортса", search: "Bashkir Башҡортса" },
  { name: "Berber", native: "ⵜⴰⵎⴰⵣⵉⵖⵜ", search: "Berber Tamazight ⵜⴰⵎⴰⵣⵉⵖⵜ" },
  { name: "Bihari", native: "भोजपुरी", search: "Bihari भोजपुरी" },
  { name: "Chamorro", native: "Chamoru", search: "Chamorro Chamoru" },
  { name: "Cree", native: "ᓀᐦᐃᔭᐍᐏᐣ", search: "Cree ᓀᐦᐃᔭᐍᐏᐣ" },
  { name: "Dari", native: "دری", search: "Dari دری" },
  { name: "Divehi", native: "ދިވެހި", search: "Divehi Maldivian ދިވެހި" },
  { name: "Dzongkha", native: "རྫོང་ཁ", search: "Dzongkha རྫོང་ཁ" },
  { name: "Fijian", native: "Vosa Vakaviti", search: "Fijian Vosa Vakaviti" },
  { name: "Gilbertese", native: "Taetae ni Kiribati", search: "Gilbertese Kiribati" },
  { name: "Inuktitut", native: "ᐃᓄᒃᑎᑐᑦ", search: "Inuktitut ᐃᓄᒃᑎᑐᑦ" },
  { name: "Kalmyk", native: "Хальмг", search: "Kalmyk Хальмг" },
  { name: "Komi", native: "Коми", search: "Komi Коми" },
  { name: "Mari", native: "Марий йылме", search: "Mari Марий йылме" },
  { name: "Marshallese", native: "Kajin M̧ajeļ", search: "Marshallese Kajin M̧ajeļ" },
  { name: "Moksha", native: "Мокшень", search: "Moksha Мокшень" },
  { name: "Sango", native: "Sängö", search: "Sango Sängö" },
  { name: "Tongan", native: "Lea faka-Tonga", search: "Tongan Lea faka-Tonga" },
  { name: "Tuvan", native: "Тыва дыл", search: "Tuvan Тыва дыл" },
  { name: "Udmurt", native: "Удмурт", search: "Udmurt Удмурт" },
  { name: "Yakut", native: "Саха тыла", search: "Yakut Sakha Саха тыла" },
  { name: "Aramaic", native: "ܐܪܡܝܐ", search: "Aramaic ܐܪܡܝܐ" },
  { name: "Bislama", native: "Bislama", search: "Bislama Vanuatu" },
  { name: "Chuvash", native: "Чӑвашла", search: "Chuvash Чӑвашла" },
  { name: "Crimean Tatar", native: "Qırımtatar", search: "Crimean Tatar Qırımtatar" },
  { name: "Ewe", native: "Eʋegbe", search: "Ewe Eʋegbe" },
  { name: "Fang", native: "Fang", search: "Fang Equatorial Guinea" },
  { name: "Gagauz", native: "Gagauz", search: "Gagauz Moldova" },
  { name: "Gikuyu", native: "Gĩkũyũ", search: "Gikuyu Kikuyu" },
  { name: "Herero", native: "Otjiherero", search: "Herero Otjiherero" },
  { name: "Karakalpak", native: "Қарақалпақ", search: "Karakalpak Қарақалпақ" },
  { name: "Khoisan", native: "Khoekhoegowab", search: "Khoisan Nama Damara" },
  { name: "Luba-Katanga", native: "Kiluba", search: "Luba-Katanga Kiluba" },
  { name: "Luganda", native: "Luganda", search: "Luganda Ganda" },
  { name: "Luo", native: "Dholuo", search: "Luo Dholuo" },
  { name: "Moldovan", native: "Limba moldovenească", search: "Moldovan Limba moldovenească" },
  { name: "Moore", native: "Mòoré", search: "Moore Mossi Mòoré" },
  { name: "Ndebele", native: "isiNdebele", search: "Ndebele isiNdebele" },
  { name: "Nuer", native: "Thok Nath", search: "Nuer Thok Nath" },
  { name: "Papiamento", native: "Papiamentu", search: "Papiamento Papiamentu" },
  { name: "Tswana", native: "Setswana", search: "Tswana Setswana" },
  { name: "Tsonga", native: "Xitsonga", search: "Tsonga Xitsonga" },
  { name: "Tumbuka", native: "chiTumbuka", search: "Tumbuka chiTumbuka" },
  { name: "Venda", native: "Tshivenḓa", search: "Venda Tshivenḓa" }
].sort((a, b) => a.name.localeCompare(b.name));

export const proficiencyLevels = [
  { level: 5, label: "Native" },
  { level: 4, label: "Fluent" },
  { level: 3, label: "Professional" },
  { level: 2, label: "Conversational" },
  { level: 1, label: "Basic" },
]; 