import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";
import {
  Box,
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import mixpanel from "mixpanel-browser";
import Home from "./components/Home";
import { auth, firestore } from "./firebase";
import Chat from "./components/Chat";
import { TopBar } from "./components/TopBar";
import { Terms } from './components/Terms.jsx';
import { Privacy } from './components/Privacy.jsx';
import { Contact } from './components/Contact.jsx';
import { CookiePolicy } from './components/CookiePolicy.jsx';
import About from './components/About';
import PageWithFooter from "./components/PageWithFooter";
import EarlyAccessDialog from "./components/EarlyAccessDialog";
import NotFound from "./components/NotFound";

mixpanel.init("df6a9a59ac8d41abe2eb400ea15b5c1a");

// Wrapper component to handle URL parameters and dialog state
const AppContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const usersRef = firestore.collection("users");
  const [user] = useAuthState(auth);
  let query = usersRef.where("uid", "==", "asdfasdf");

  if (user) {
    query = usersRef.where("uid", "==", user.uid);
  }

  const [users] = useCollectionData(query, { idField: "id" });

  // Get dialog state from URL
  const searchParams = new URLSearchParams(location.search);
  const authMode = searchParams.get('auth');
  const isDialogOpen = authMode === 'signup' || authMode === 'login';
  const dialogTab = authMode === 'login' ? 1 : 0;

  useEffect(() => {
    if (users && users[0] && users[0].isAdmin === true) {
      setIsAdmin(true);
    }
  }, [users]);

  const handleShowDialog = (tab) => {
    // Update URL parameter based on tab
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('auth', tab === 0 ? 'signup' : 'login');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const handleDialogClose = () => {
    // Remove auth parameter from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('auth');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const handleTabChange = (newValue) => {
    // Update URL parameter based on tab
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('auth', newValue === 0 ? 'signup' : 'login');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Box sx={{ zIndex: 1, width: "100%", bgcolor: "rgb(255, 254, 249)" }}>
      <div>
        <TopBar isAdmin={isAdmin} onShowDialog={handleShowDialog} />
        <Routes>
          <Route path="/" element={<Home tab="new" isAdmin={isAdmin} onShowDialog={handleShowDialog} />} />
          <Route path="/about" element={<PageWithFooter><About /></PageWithFooter>} />
          <Route path="/chats" element={<Home tab="chats" isAdmin={isAdmin} onShowDialog={handleShowDialog} />} />
          <Route path="/chat" element={<Chat isAdmin={isAdmin} />} />
          <Route path="/terms" element={<PageWithFooter><Terms /></PageWithFooter>} />
          <Route path="/contact" element={<PageWithFooter><Contact /></PageWithFooter>} />
          <Route path="/privacy" element={<PageWithFooter><Privacy /></PageWithFooter>} />
          <Route path="/cookies" element={<PageWithFooter><CookiePolicy /></PageWithFooter>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <EarlyAccessDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          initialTab={dialogTab}
          onTabChange={handleTabChange}
        />
      </div>
    </Box>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
