import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm } from '@formspree/react';
import { v4 as uuidv4 } from 'uuid';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Tabs,
  Tab,
  Box,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  Typography,
  Menu,
  Snackbar,
  Alert,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  Grid,
  Popper,
  MenuList,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import {
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  RedditShareButton,
  EmailIcon,
  WhatsappIcon,
  TelegramIcon,
  LinkedinIcon,
  TwitterIcon,
  FacebookIcon,
  RedditIcon,
} from "react-share";
import { languageOptions, proficiencyLevels } from '../data/languageOptions';
import { firestore } from "../firebase";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailIcon from "@mui/icons-material/Mail";
import ShareIcon from "@mui/icons-material/Share";

function isValidEmail(email) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
}

const categoryOptions = [
  "Health & Wellness",
  "Life & Time Management",
  "Home & Lifestyle",
  "Finance",
  "Family & Relationships",
  "Leisure, Hobbies, Art & Entertainment",
  "Career & Professional Development",
  "Knowledge & Skills Development",
  "Administrative & Legal Support",
  "Shopping & Maintenance Support",
  "Travel & Transportation",
  "Pet Care",
  "Social Impact, Philanthropy & Sustainability",
  "Creativity & Self-Expression",
];

const LocationAutocomplete = React.memo(({ value, onChange, onSelect }) => {
  const inputRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [loadingScript, setLoadingScript] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const googleMapsScriptId = 'google-maps-script';

    const loadGoogleMapsScript = async () => {
      if (window.google?.maps?.places || loadingScript || document.getElementById(googleMapsScriptId)) {
        return;
      }

      try {
        setLoadingScript(true);
        
        // Remove any existing Google Maps scripts and clean up
        const cleanup = () => {
          const existingScripts = document.querySelectorAll('script[src*="maps.googleapis.com"]');
          existingScripts.forEach(script => script.remove());
          const elements = document.querySelectorAll('.pac-container, [class*="gm-"]');
          elements.forEach(element => element.remove());
        };
        
        cleanup();

        // Get API key from environment variable only
        const apiKey = "AIzaSyAcd857VIj_gsIefpq6hBPvC_CnKGtXkBk";
        if (!apiKey) {
          throw new Error('Google Maps API key is not configured');
        }

        // Create and load new script with better error handling
        await new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.id = googleMapsScriptId;
          script.type = 'text/javascript';
          script.async = true;
          script.defer = true;
          script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
          
          // Define initMap callback
          window.initMap = () => {
            if (window.google?.maps?.places) {
              if (isMounted) {
                setIsLoaded(true);
                setLoadingScript(false);
              }
              resolve();
            } else {
              reject(new Error('Google Maps API failed to initialize properly'));
            }
          };
          
          script.onerror = () => {
            cleanup();
            if (isMounted) {
              setError('Failed to load location search');
              setLoadingScript(false);
            }
            reject(new Error('Failed to load Google Maps script'));
          };

          document.head.appendChild(script);
        });
      } catch (err) {
        console.error('Error loading Google Maps:', err);
        if (isMounted) {
          setError(err.message === 'Google Maps API key is not configured' 
            ? 'Location search is not configured' 
            : 'Location search is temporarily unavailable');
          setLoadingScript(false);
        }
      }
    };

    if (!window.google?.maps?.places) {
      loadGoogleMapsScript();
    } else {
      setIsLoaded(true);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!isLoaded || !inputRef.current || error) return;

    let autocomplete = null;

    try {
      if (!window.google?.maps?.places) {
        throw new Error('Google Maps Places API is not available');
      }

      autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['(cities)'],
        fields: ['formatted_address', 'geometry', 'name'],
      });

      const placeChangedListener = autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place?.formatted_address) {
          onSelect(place.formatted_address);
        }
      });

      // Prevent form submission on enter
      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

      inputRef.current.addEventListener('keydown', handleKeyDown);

      return () => {
        if (placeChangedListener) {
          window.google.maps.event.removeListener(placeChangedListener);
        }
        if (autocomplete) {
          window.google.maps.event.clearInstanceListeners(autocomplete);
        }
        if (inputRef.current) {
          inputRef.current.removeEventListener('keydown', handleKeyDown);
        }
      };
    } catch (err) {
      console.error('Error initializing autocomplete:', err);
      setError('Location search initialization failed');
    }
  }, [isLoaded, onSelect, error]);

  return (
    <>
      <TextField
        margin="dense"
        id="location-autocomplete"
        label="Location (City, Country)"
        placeholder={error ? "Location search unavailable" : isLoaded ? "Start typing a city name..." : "Loading location search..."}
        fullWidth
        variant="outlined"
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        autoComplete="off"
        error={!!error}
        helperText={error}
        disabled={!!error}
        sx={{ 
          mb: 2,
          position: 'relative',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
              borderColor: '#bdbdbd',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1976d2',
            },
          },
        }}
      />
      <style>
        {`
          .pac-container {
            z-index: 9999999 !important;
            margin-top: 2px;
            padding: 8px;
            border-radius: 4px;
            box-shadow: 0 4px 20px rgba(0,0,0,0.15);
            border: 1px solid #e0e0e0;
            background-color: white;
            position: absolute !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            width: calc(100% - 48px) !important;
          }
          .pac-container:after {
            display: none;
          }
          .pac-item {
            padding: 8px 12px;
            cursor: pointer;
            font-family: inherit;
            border-top: none;
            font-size: 14px;
            line-height: 1.5;
          }
          .pac-item:hover {
            background-color: #f5f5f5;
          }
          .pac-item-query {
            font-size: 14px;
            padding-right: 4px;
          }
          .pac-icon {
            margin-right: 8px;
          }
          .pac-matched {
            font-weight: bold;
          }
          .pac-item:first-of-type {
            border-top: none;
          }
        `}
      </style>
    </>
  );
});

function generateShortId() {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 10; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export default function EarlyAccessDialog({ open, onClose, isCloseable = true, initialTab = 0, onTabChange }) {
  const [sessionId] = useState(() => generateShortId());
  const [tabValue, setTabValue] = useState(initialTab);
  const [showDetailedForm, setShowDetailedForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    location: "",
    languages: [],
    selectedServices: [],
    availability: "",
    additionalInfo: "",
    fullName: "",
    tasksToDelegate: "",
    interestedCategories: [],
    howDidYouHear: "",
    focusIfFreeTime: "",
  });
  const [selectedLocations, setSelectedLocations] = useState([]);
  const autocompleteRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formProgress, setFormProgress] = useState(0);
  const [emailError, setEmailError] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [loginError, setLoginError] = useState("");
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });
  const [initialFormState, handleInitialSubmit] = useForm("mzzdgeow");
  const [detailedFormState, handleDetailedSubmit] = useForm("mzzdgeow");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [locationInput, setLocationInput] = useState("");
  const locationInputRef = useRef(null);

  // Add URL handling
  useEffect(() => {
    if (open) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('auth', tabValue === 0 ? 'signup' : 'login');
      window.history.pushState({}, '', newUrl);
    } else {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('auth');
      window.history.pushState({}, '', newUrl);
    }
  }, [open, tabValue]);

  // Add URL handling for tab changes
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('auth', newValue === 0 ? 'signup' : 'login');
    window.history.pushState({}, '', newUrl);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  // Add URL handling for close
  const handleClose = () => {
    if (isCloseable && onClose) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('auth');
      window.history.pushState({}, '', newUrl);
      onClose();
    }
  };

  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;
    return `${baseUrl}${currentPath}?auth=signup&ref=${sessionId}`;
  };

  useEffect(() => {
    setTabValue(initialTab);
  }, [initialTab]);

  const handleJoinWaitingList = async () => {
    setEmailError("");
    if (!formData.email.trim()) {
      setEmailError("Email is required");
      return;
    }
    if (!isValidEmail(formData.email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    try {
      await handleInitialSubmit({
        email: formData.email,
        sessionId: sessionId,
        submissionType: 'initial_waitlist',
        _subject: 'New Waitlist Signup'
      });

      const waitlistRef = firestore.collection('waitlist');
      await waitlistRef.doc(sessionId).set({
        email: formData.email,
        sessionId: sessionId,
        createdAt: new Date(),
        status: 'initial',
      });
      
      setShowDetailedForm(true);
      setSnackbar({
        open: true,
        message: 'Successfully joined the waiting list!',
        severity: 'success'
      });
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setEmailError("An error occurred. Please try again.");
      setSnackbar({
        open: true,
        message: 'Failed to join waitiimage.pngng list. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleEmailKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleJoinWaitingList();
    }
  };

  const handleProficiencyClick = (level) => {
    setFormData((prev) => ({
      ...prev,
      languages: [
        ...prev.languages,
        { name: selectedLanguage, proficiency: level },
      ],
    }));
    setSelectedLanguage(null);
    setAnchorEl(null);
  };

  useEffect(() => {
  }, [selectedLocations]);

  useEffect(() => {
    let progress = 0;
    const totalFields = 10;

    if (formData.email?.trim()) progress += 1;
    if (formData.fullName?.trim()) progress += 1;
    if (selectedLocations.length > 0) progress += 1;
    if (formData.languages.length > 0) progress += 1;
    if (formData.tasksToDelegate?.trim()) progress += 1;
    if (formData.interestedCategories?.length > 0) progress += 1;
    if (formData.focusIfFreeTime?.trim()) progress += 1;
    if (formData.howDidYouHear?.trim()) progress += 1;
    if (formData.additionalInfo?.trim()) progress += 1;

    const calculatedProgress = Math.min((progress / totalFields) * 100, 92);
    setFormProgress(calculatedProgress);
  }, [formData, selectedLocations]);

  const handleLoginSubmit = () => {
    setLoginError("User not found");
  };

  const handleLoginKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLoginSubmit();
    }
  };

  const handleSubmitFullForm = async () => {
    try {
      await handleDetailedSubmit({
        ...formData,
        sessionId: sessionId,
        submissionType: 'detailed_form',
        _subject: 'Detailed Waitlist Form Submission'
      });

      const waitlistRef = firestore.collection('waitlist');
      await waitlistRef.doc(sessionId).update({
        fullName: formData.fullName,
        location: selectedLocations,
        languages: formData.languages,
        tasksToDelegate: formData.tasksToDelegate,
        interestedCategories: formData.interestedCategories,
        focusIfFreeTime: formData.focusIfFreeTime,
        howDidYouHear: formData.howDidYouHear,
        additionalInfo: formData.additionalInfo,
        status: 'complete',
        updatedAt: new Date(),
      });

      setIsSubmitted(true);
      setSnackbar({
        open: true,
        message: 'Thank you for completing your profile!',
        severity: 'success'
      });
      
      if (window.mixpanel) {
        window.mixpanel.track('Waitlist Form Completed', {
          email: formData.email,
          sessionId: sessionId
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: 'Failed to submit form. Please try again.',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    if (initialFormState.errors) {
      setSnackbar({
        open: true,
        message: 'Failed to join waiting list. Please try again.',
        severity: 'error'
      });
    }
    if (detailedFormState.errors) {
      setSnackbar({
        open: true,
        message: 'Failed to submit form. Please try again.',
        severity: 'error'
      });
    }
  }, [initialFormState.errors, detailedFormState.errors]);

  const handleLocationSelect = useCallback((address) => {
    setLocationInput('');
    setSelectedLocations(prev => [...prev, address]);
    setFormData(prev => ({
      ...prev,
      location: [...(prev.location ? [prev.location] : []), address].join(", "),
    }));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && isCloseable) {
          handleClose();
        }
      }}
      disableEscapeKeyDown={!isCloseable}
      aria-labelledby="early-access-dialog-title"
      sx={{
        width: "calc(100vw - 20px)",
        maxWidth: "1038px",
        margin: "auto",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          zIndex: 1000000,
        },
        "& .MuiDialog-paper": {
          zIndex: 1000001,
          width: "100%",
          height: (showDetailedForm && tabValue === 0) ? "calc(100vh - 140px)" : isSubmitted ? "auto" : "calc(100vh - 400px)",
          maxHeight: (showDetailedForm && tabValue === 0) ? "calc(100vh - 40px)" : "none",
          overflowY: "auto",
          position: "relative",
        },
        "& .MuiPopper-root": {
          position: "absolute !important",
          zIndex: 1000002,
        },
      }}
    >
      {isCloseable && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle
        id="early-access-dialog-title"
        sx={{ 
          borderBottom: !isSubmitted ? 1 : 0, 
          borderColor: "divider",
          position: 'relative',
          p: 0,
          '& .MuiTabs-root': {
            position: 'relative',
            zIndex: 1
          }
        }}
      >
        {!isSubmitted && (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="auth tabs"
            centered
          >
            <Tab label="Sign Up" />
            <Tab label="Login" />
          </Tabs>
        )}
      </DialogTitle>
      <DialogContent>
        {tabValue === 0 && !isSubmitted && (
          <Box sx={{ pt: 2 }}>
            <AnimatePresence mode="wait">
              {!showDetailedForm ? (
                <motion.div
                  key="initial-form"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box sx={{ textAlign: 'center', mb: 4 }}>

                    <Typography variant="h6" sx={{ 
                      color: 'text.secondary',
                      fontWeight: 500,
                      maxWidth: '600px',
                      mx: 'auto',
                      mb: 2
                    }}>
                      Due to overwhelming demand, we're currently accepting new users through our exclusive waiting list
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1.5,
                      alignItems: 'center',
                      mb: 3
                    }}>
                      <Typography variant="body1" sx={{ 
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}>
                        <CheckCircleIcon sx={{ color: 'success.main', fontSize: 20 }} />
                        Leave your email to secure your spot
                      </Typography>
                      <Typography variant="body1" sx={{ 
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}>
                        <CheckCircleIcon sx={{ color: 'success.main', fontSize: 20 }} />
                        We'll notify you as soon as access becomes available
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    autoFocus={window.innerWidth > 768}
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                      if (emailError) setEmailError("");
                    }}
                    onKeyPress={handleEmailKeyPress}
                    error={Boolean(emailError)}
                    helperText={emailError}
                    sx={{ mb: 2 }}
                    inputProps={{
                      readOnly: window.innerWidth <= 768,
                      onTouchStart: (e) => {
                        if (window.innerWidth <= 768) {
                          e.target.readOnly = false;
                        }
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleJoinWaitingList}
                    sx={{
                      mt: 1,
                      width: "100%",
                      bgcolor: "black",
                      color: "white",
                      "&:hover": {
                        bgcolor: "#333",
                      },
                    }}
                  >
                    Join Waiting List
                  </Button>
                </motion.div>
              ) : (
                <motion.div
                  key="detailed-form"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, type: "spring", bounce: 0.4 }}
                    sx={{ mb: 0 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        py: 2,
                        px: 3,
                        mb: 0,
                        textAlign: "center",
                        borderRadius: 2,
                      }}
                    >
                      <motion.div
                        animate={{
                          y: [0, -5, 0],
                          rotate: [0, 5, -5, 0],
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          repeatType: "reverse",
                        }}
                      >
                        <Typography variant="h4" sx={{ mb: 1.5, fontSize: "2.5rem" }}>
                          🎊
                        </Typography>
                      </motion.div>
                      <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                      Thank You for Joining!
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary", maxWidth: "500px", mb: 1 }}>
                      You're on the waiting list - we'll email you when a spot opens up. Complete your profile below to move up in line and help us personalize our service to your needs.
                      </Typography>
                    </Box>
                  </motion.div>

                  <Box sx={{ mb: 2, position: "relative" }}>
                    <Box
                      sx={{
                        position: "sticky",
                        top: "0",
                        width: "100%",
                        height: "100px",
                        my: 0.5,
                        overflow: "visible",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: `${Math.min((formProgress / 100) * 92, 92)}%`,
                          top: "18px",
                          transform: "translateX(-50%)",
                          fontSize: "56px",
                          transition: "left 0.5s ease-out",
                          zIndex: 2,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                          mx: { xs: 3, sm: 0 },
                        }}
                      >
                        🚶‍➡️
                        <Typography
                          sx={{
                            fontSize: "12px",
                            bgcolor: "black",
                            color: "white",
                            px: 1.5,
                            py: 0.5,
                            position: "relative",
                            left: "-15px",
                            borderRadius: "12px",
                            fontWeight: "bold",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                            userSelect: "none",
                            transform: "translateY(-50%)",
                            animation: "pulse 2s ease-in-out infinite",
                            "@keyframes pulse": {
                              "0%": { opacity: 0.6 },
                              "50%": { opacity: 1 },
                              "100%": { opacity: 0.6 },
                            },
                          }}
                        >
                           You in line
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          px: { xs: 3, sm: 1 },
                          bottom: "15px",
                          overflow: "hidden",
                          "& > span": {
                            fontSize: "30px",
                            filter: "drop-shadow(0 1px 2px rgba(0,0,0,0.1)) grayscale(0.8)",
                            opacity: 0.7,
                            lineHeight: 1,
                          },
                        }}
                      >
                        {[
                          "🚶🏾‍♂️", "🚶🏽‍♂️", "🚶🏼‍♂️", "🚶🏻‍♂️", "🚶‍♂️", "🚶🏿‍♂️",
                          "🚶🏾‍♂️", "🚶🏽‍♂️", "🚶🏿‍♀️", "🚶🏾‍♀️", "🚶🏽‍♀️", "🚶🏼‍♀️",
                          "🚶🏻‍♀️", "🚶‍♀️", "🚶🏿‍♀️", "🚶🏾‍♀️", "🚶🏾‍♂️", "🚶🏽‍♂️",
                          "🚶🏼‍♂️", "🚶🏻‍♂️", "🚶‍♂️", "🚶🏿‍♂️", "🚶🏾‍♂️", "🚶🏽‍♂️",
                        ].map((emoji, i) => (
                          <span
                            key={i}
                            style={{
                              transform: "scaleX(-1)",
                              marginLeft: i % 2 === 0 ? "-6px" : "-2px",
                            }}
                          >
                            {emoji}
                          </span>
                        ))}
                      </Box>
                    </Box>
                  </Box>

                  <TextField
                    margin="dense"
                    id="fullName"
                    label="Full Name"
                    fullWidth
                    variant="outlined"
                    value={formData.fullName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        fullName: e.target.value,
                      }))
                    }
                    sx={{ mb: 1 }}
                    inputProps={{
                      readOnly: window.innerWidth <= 768,
                      onTouchStart: (e) => {
                        if (window.innerWidth <= 768) {
                          e.target.readOnly = false;
                        }
                      }
                    }}
                  />

                  <TextField
                    margin="dense"
                    id="location"
                    label="Location (City, Country)"
                    fullWidth
                    variant="outlined"
                    value={formData.location || ''}
                    onChange={(e) => {
                      const newLocation = e.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        location: newLocation,
                      }));
                      // Update progress immediately when location changes
                      let progress = 0;
                      const totalFields = 10;
                      
                      if (formData.email?.trim()) progress += 1;
                      if (formData.fullName?.trim()) progress += 1;
                      if (newLocation?.trim()) progress += 1;
                      if (formData.languages.length > 0) progress += 1;
                      if (formData.tasksToDelegate?.trim()) progress += 1;
                      if (formData.interestedCategories?.length > 0) progress += 1;
                      if (formData.focusIfFreeTime?.trim()) progress += 1;
                      if (formData.howDidYouHear?.trim()) progress += 1;
                      if (formData.additionalInfo?.trim()) progress += 1;

                      const calculatedProgress = Math.min((progress / totalFields) * 100, 92);
                      setFormProgress(calculatedProgress);
                    }}
                    placeholder="Enter your location..."
                    sx={{ mb: 2 }}
                    inputProps={{
                      readOnly: window.innerWidth <= 768,
                      onTouchStart: (e) => {
                        if (window.innerWidth <= 768) {
                          e.target.readOnly = false;
                        }
                      }
                    }}
                  />

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Autocomplete
                      multiple
                      id="languages-autocomplete"
                      options={languageOptions}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : `${option.name} (${option.native})`
                      }
                      filterOptions={(options, { inputValue }) => {
                        const searchTerm = inputValue.toLowerCase();
                        return options.filter((option) =>
                          option.search.toLowerCase().includes(searchTerm)
                        );
                      }}
                      value={formData.languages.map(
                        (lang) => languageOptions.find((l) => l.name === lang.name) || lang
                      )}
                      onChange={(event, newValue, reason, details) => {
                        if (reason === "selectOption" && details?.option) {
                          setSelectedLanguage(details.option.name);
                          const rect = event.currentTarget.getBoundingClientRect();
                          setAnchorEl({
                            clientWidth: rect.width,
                            clientHeight: rect.height,
                            getBoundingClientRect: () => rect,
                          });
                        } else if (reason === "removeOption") {
                          setFormData((prev) => ({
                            ...prev,
                            languages: prev.languages.filter(
                              (lang) => lang.name !== (details.option.name || details.option)
                            ),
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Languages"
                          placeholder="Search languages in English or native script"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li 
                          {...props} 
                          onClick={(e) => {
                            props.onClick(e);
                            const rect = e.currentTarget.getBoundingClientRect();
                            setAnchorEl({
                              clientWidth: rect.width,
                              clientHeight: rect.height,
                              getBoundingClientRect: () => rect,
                            });
                            setSelectedLanguage(option.name);
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "column", py: 1 }}>
                            <Typography>{option.name}</Typography>
                            <Typography variant="caption" color="text.secondary">
                              {option.native}
                            </Typography>
                          </Box>
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        formData.languages.map((lang, index) => (
                          <Chip
                            label={
                              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                                {`${lang.name} (${
                                  languageOptions.find((l) => l.name === lang.name)?.native ||
                                  lang.name
                                })`}
                                <span style={{ color: "#FFD700", marginLeft: "4px" }}>
                                  {Array(lang.proficiency).fill("★").join("")}
                                </span>
                              </Box>
                            }
                            {...getTagProps({ index })}
                            sx={{ m: 0.5 }}
                          />
                        ))
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          p: 1,
                        },
                      }}
                    />
                    <Popper
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      placement="right-start"
                      modifiers={[
                        {
                          name: 'preventOverflow',
                          enabled: true,
                          options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: true,
                            rootBoundary: 'document',
                            padding: 8,
                          },
                        }
                      ]}
                      style={{
                        position: "absolute",
                        zIndex: 1000002,
                      }}
                    >
                      <Paper
                        elevation={4}
                        sx={{
                          minWidth: '200px',
                          ml: 1,
                          mt: 0,
                          boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
                          backgroundColor: 'white',
                          outline: 'none',
                        }}
                        tabIndex={-1}
                        onKeyDown={(e) => {
                          if (e.key === 'Escape') {
                            setAnchorEl(null);
                            setSelectedLanguage(null);
                          }
                        }}
                      >
                        <ClickAwayListener onClickAway={() => {
                          setAnchorEl(null);
                          setSelectedLanguage(null);
                        }}>
                          <MenuList
                            autoFocus
                            autoFocusItem={true}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                const selectedIndex = proficiencyLevels.findIndex(level => 
                                  document.activeElement === document.querySelector(`[data-level="${level.level}"]`)
                                );
                                if (selectedIndex !== -1) {
                                  handleProficiencyClick(proficiencyLevels[selectedIndex].level);
                                }
                              }
                            }}
                          >
                            {proficiencyLevels.map((level) => (
                              <MenuItem
                                key={level.level}
                                onClick={() => handleProficiencyClick(level.level)}
                                data-level={level.level}
                                tabIndex={0}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  minWidth: "200px",
                                  '&:focus': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    outline: 'none',
                                  },
                                }}
                              >
                                <span style={{ color: "#FFD700" }}>
                                  {Array(level.level).fill("★").join("")}
                                </span>
                                <Typography variant="body2">{level.label}</Typography>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Popper>
                  </FormControl>

                  <TextField
                    margin="dense"
                    id="tasksToDelegate"
                    label="Tasks I want to Delegate"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    value={formData.tasksToDelegate}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        tasksToDelegate: e.target.value,
                      }))
                    }
                    sx={{ mb: 2 }}
                  />

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="interested-categories-label" sx={{ bgcolor: 'white', px: 1 }}>
                      Interested Categories
                    </InputLabel>
                    <Select
                      labelId="interested-categories-label"
                      id="interested-categories"
                      multiple
                      value={formData.interestedCategories}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          interestedCategories: e.target.value,
                        }))
                      }
                      label="Interested Categories"
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                    >
                      {categoryOptions.map((category) => (
                        <MenuItem key={category} value={category} sx={{ py: 0.5 }}>
                          <Checkbox checked={formData.interestedCategories.indexOf(category) > -1} />
                          <ListItemText primary={category} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    margin="dense"
                    id="focusIfFreeTime"
                    label="If You Had More Free Time, What Would You Focus On?"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    value={formData.focusIfFreeTime}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        focusIfFreeTime: e.target.value,
                      }))
                    }
                    sx={{ mb: 2 }}
                    inputProps={{
                      readOnly: window.innerWidth <= 768,
                      onTouchStart: (e) => {
                        if (window.innerWidth <= 768) {
                          e.target.readOnly = false;
                        }
                      }
                    }}
                  />

                  <TextField
                    margin="dense"
                    id="howDidYouHear"
                    label="How Did You Hear About Us"
                    fullWidth
                    variant="outlined"
                    value={formData.howDidYouHear}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        howDidYouHear: e.target.value,
                      }))
                    }
                    sx={{ mb: 2 }}
                    inputProps={{
                      readOnly: window.innerWidth <= 768,
                      onTouchStart: (e) => {
                        if (window.innerWidth <= 768) {
                          e.target.readOnly = false;
                        }
                      }
                    }}
                  />

                  <TextField
                    margin="dense"
                    id="additionalInfo"
                    label="Additional Information (Optional)"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    value={formData.additionalInfo}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        additionalInfo: e.target.value,
                      }))
                    }
                    sx={{ mb: 2 }}
                    placeholder="Tell us more about your specific needs or preferences..."
                    inputProps={{
                      readOnly: window.innerWidth <= 768,
                      onTouchStart: (e) => {
                        if (window.innerWidth <= 768) {
                          e.target.readOnly = false;
                        }
                      }
                    }}
                  />


                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmitFullForm}
                    sx={{
                      mt: 1,
                      bgcolor: "black",
                      color: "white",
                      "&:hover": {
                        bgcolor: "#333",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
        )}
        {tabValue === 0 && isSubmitted && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, type: "spring", bounce: 0.4 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                py: 6,
                px: 4,
                textAlign: "center",
                background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.02) 100%)",
              }}
            >
              <Box 
                sx={{
                  position: 'relative',
                  mb: 4,
                }}
              >
                <motion.div
                  animate={{
                    y: [0, -10, 0],
                    rotate: [0, 10, -10, 0],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                >
                  <Typography 
                    variant="h2" 
                    sx={{ 
                      fontSize: "4rem",
                      filter: "drop-shadow(0 4px 8px rgba(0,0,0,0.1))",
                      mb: 0
                    }}
                  >
                    🎉
                  </Typography>
                </motion.div>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '200%',
                    height: '100%',
                    background: 'radial-gradient(circle, rgba(255,223,0,0.1) 0%, rgba(255,255,255,0) 70%)',
                    zIndex: -1,
                  }}
                />
              </Box>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <Typography 
                  variant="h3" 
                  sx={{ 
                    mb: 3, 
                    fontWeight: 800,
                    background: 'linear-gradient(45deg, #000000 30%, #333333 90%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    textShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  }}
                >
                  All Set!
                </Typography>
                <Typography 
                  variant="h4" 
                  sx={{ 
                    mb: 4, 
                    fontWeight: 600,
                    color: 'text.primary',
                    opacity: 0.9,
                  }}
                >
                  You're on the Waitlist
                </Typography>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mb: 2, 
                    color: "text.secondary", 
                    maxWidth: "600px", 
                    fontSize: "1.1rem",
                    lineHeight: 1.6,
                  }}
                >
                  You're in! We're reviewing applications to ensure a premium experience for everyone.
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    mb: 4,
                    color: "text.secondary", 
                    maxWidth: "600px",
                    fontSize: "0.95rem",
                  }}
                >
                  We'll email you as soon as we're ready to give you access! 📧
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    mt: 5, 
                    mb: 1,
                    color: "text.primary", 
                    maxWidth: "600px", 
                    fontSize: "1.1rem",
                    fontWeight: 500,
                  }}
                >
                  Share to move up!
                </Typography>
              </motion.div>

              <Box sx={{ width: "100%", maxWidth: "600px", mb: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    mb: 2,
                    borderRadius: 1,
                    border: "1px solid #e0e0e0",
                    p: 1.5,
                    bgcolor: "white"
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      flexGrow: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textAlign: "left",
                      pl: 1,
                      fontFamily: "monospace"
                    }}
                  >
                    {getShareUrl()}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(getShareUrl());
                      setSnackbar({
                        open: true,
                        message: 'Referral link copied to clipboard!',
                        severity: 'success'
                      });
                    }}
                    sx={{ ml: 1 }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Typography variant="caption" sx={{ color: "text.secondary", display: "block", mb: 2, textAlign: "center" }}>
                  🚀 Refer friends to skip ahead together! 🤝
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "center",
                    "& button": {
                      outline: "none !important",
                    },
                  }}
                >
                  <WhatsappShareButton
                    url={getShareUrl()}
                    title="Join the waitlist for Intelligent personal assistance!"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>

                  <TelegramShareButton
                    url={getShareUrl()}
                    title="Join the waitlist for Intelligent personal assistance!"
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>

                  <LinkedinShareButton
                    url={getShareUrl()}
                    title="Join the waitlist for Intelligent personal assistance!"
                    summary="Get early access to personalized assistance for all your needs."
                    source={window.location.origin}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>

                  <TwitterShareButton
                    url={getShareUrl()}
                    title="Join the waitlist for Intelligent personal assistance!"
                    hashtags={["Concierge", "PersonalAssistant"]}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>

                  <FacebookShareButton
                    url={getShareUrl()}
                    quote="Join the waitlist for Intelligent personal assistance!"
                    hashtag="#Concierge"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <RedditShareButton
                    url={getShareUrl()}
                    title="Join the waitlist for Intelligent personal assistance!"
                  >
                    <RedditIcon size={32} round />
                  </RedditShareButton>

                  <EmailShareButton
                    url={getShareUrl()}
                    subject="Join the waitlist for Intelligent personal assistance!"
                    body="Hey! I thought you might be interested in this new personal concierge service. Join the waiting list using my referral link:"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </Box>
              </Box>
            </Box>
          </motion.div>
        )}
        {tabValue === 1 && !isSubmitted && (
          <Box sx={{ pt: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              id="login-email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value={loginFormData.email}
              onChange={(e) => setLoginFormData(prev => ({ ...prev, email: e.target.value }))}
              onKeyPress={handleLoginKeyPress}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              value={loginFormData.password}
              onChange={(e) => setLoginFormData(prev => ({ ...prev, password: e.target.value }))}
              onKeyPress={handleLoginKeyPress}
              sx={{ mb: loginError ? 1 : 2 }}
            />
            {loginError && (
              <Typography 
                color="error" 
                variant="body2" 
                sx={{ mb: 2, ml: 1 }}
              >
                {loginError}
              </Typography>
            )}
            <Button
              variant="contained"
              fullWidth
              onClick={handleLoginSubmit}
              sx={{
                mt: 1,
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  bgcolor: "#333",
                },
              }}
            >
              Login
            </Button>
          </Box>
        )}
      </DialogContent>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ 
          top: '80px !important',
          zIndex: 9999
        }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
} 