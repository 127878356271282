import React, { Suspense, lazy, useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ScrollAnimation from './ScrollAnimation';
import servicesData from '../services.json';
import mixpanel from 'mixpanel-browser';

// Lazy load the CarouselRow component
const CarouselRow = lazy(() => import('./CarouselRow'));

// Loading skeleton component for the carousel row
const CarouselRowSkeleton = () => {
  useEffect(() => {
    mixpanel.track('Services Loading State', {
      location: 'Services Section',
      state: 'loading'
    });
  }, []);

  return (
    <Box sx={{ marginBottom: "32px" }}>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Loading...</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 2, overflow: "hidden" }}>
        {[1, 2, 3].map((i) => (
          <Box
            key={i}
            sx={{
              width: 280,
              height: 200,
              backgroundColor: 'rgba(0,0,0,0.1)',
              borderRadius: 2,
              flexShrink: 0
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

function Services() {
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track('Services Section Viewed', {
      location: 'Services Section',
      totalServices: servicesData.services.length
    });
  }, []);

  const handleServiceClick = (service) => {
    mixpanel.track('Service Clicked', {
      location: 'Services Section',
      serviceId: service.id,
      serviceTitle: service.title,
      serviceCategory: service.category
    });
    navigate(`/chat?service=${encodeURIComponent(service.name)}`, { state: { showDialog: true, service } });
  };

  const handleSeeAll = () => {
    mixpanel.track('Services See All Clicked', {
      location: 'Services Section',
      totalServices: servicesData.services.length
    });
    navigate('/?scrollTo=services');
  };

  // Create a single carousel with all services
  const mainCarousel = {
    category: "What Can We Do for You?",
    services: servicesData.services.slice(0, 20).map(service => service.id), // Show first 20 services
    onSeeAll: handleSeeAll // Add custom onSeeAll handler
  };

  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="lg">
        <ScrollAnimation>
          <Suspense 
            fallback={<CarouselRowSkeleton />}
            onTransitionEnd={() => {
              mixpanel.track('Services Loading State', {
                location: 'Services Section',
                state: 'loaded'
              });
            }}
          >
            <CarouselRow 
              carousel={mainCarousel}
              onServiceClick={handleServiceClick}
            />
          </Suspense>
        </ScrollAnimation>
      </Container>
    </Box>
  );
}

export default Services;
