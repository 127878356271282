import { CssBaseline } from "@mui/material";
import { red, grey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

export const theme = createTheme({
  typography: {
    fontFamily: ["Hanken Grotesk", "sans-serif"].join(","),
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: "1rem",
      fontStyle: "normal",
      lineHeight: 1.5,
      fontWeight: 400,
    },
    button: {
      textTransform: "capitalize",
      fontWeight: 700,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#19857b",
      light: "#4fb3aa",
      dark: "#115a54",
    },
    background: {
      default: "rgb(255, 254, 249)",
      paper: "rgb(255, 254, 249)",
    },
    text: {
      primary: "rgb(0,0,0)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        contained: {
          backgroundColor: "#000000",
        },
        outlined: {
          color: "#000000",
          borderColor: "#000000",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
