import React from 'react';
import Footer from './Footer';
import { Box } from '@mui/material';

export const PageWithFooter = ({ children }) => {
  return (
    <>
      <Box sx={{ minHeight: 'calc(100vh - 100px)', mb: 8 }}>
        <div>
          {children}
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default PageWithFooter; 